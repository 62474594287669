<template>
    <div>
        <div class="secondary product-card glass-border full-width elevation-3">

            <div class="card-image-container" @click="$router.push({name: 'ViewProduct', params: {id: product.id} })" >
                <img class="card-image" :src="product.images.display.md" :srcset="`${product.images.display.lg} 3x, ${product.images.display.md} 2x, ${product.images.display.sm} 1x` " :alt="product.name">
            </div>


            <div class="d-flex flex-column align-start justify-space-between full-width mx-0 px-4 my-3">
                <div class="product-card-text accent--text d-flex flex-column justify-start align-start">
                    <div class="font-weight-light text-caption warning--text text--lighten-2  text-left">
                        <v-icon class="warning--text text--lighten-2 ml-n1" size="15">mdi-map-marker</v-icon>
                        {{product.location}}
                    </div>
                    <div class="font-weight-medium text-h6 text-left">{{product.name}}</div>
                    <div class="text-left">From ${{lowestPrice}} - ${{highestPrice}}</div>
                </div>
            <!-- Buying Actions -->
                <div v-if="!edit" class="full-width mt-4 mx-0 d-flex flex-row flex-wrap justify-center align-center">
                    <!-- <div class="buy-button action-button glass-border" @click="addProductToCart()"> -->
                    <div class="buy-button action-button glass-border full-width" @click="$router.push({name: 'ViewProduct', params: {id: product.id} })">
                        <div class="d-flex d-inline-block justify-center align-center text-subtitle font-weight-medium">
                            View
                        </div>
                        <!-- <v-icon small left class="white--text" >mdi-chevron-right</v-icon> -->
                    </div>

                    <div class="add-to-cart-button action-button glass-border full-width mt-2" @click="$router.push({name: 'ViewProduct', params: {id: product.id} })">
                        <div class="d-flex d-inline-block justify-center align-center text-subtitle font-weight-medium accent--text">
                            Buy
                        </div>
                        <!-- <v-icon small left class="white--text" >mdi-chevron-right</v-icon> -->
                    </div>
                </div>

                <v-card-actions v-else-if="edit && isAdmin">
                    <v-spacer></v-spacer>
                    
                    <v-btn icon @click="$router.push({ name: 'Edit', params: {id: product.id}})">
                        <v-icon class="secondary--text">mdi-square-edit-outline</v-icon>
                    </v-btn>

                    <v-btn icon @click="deleteProduct()">
                        <v-icon class="warning--text">mdi-delete</v-icon>
                    </v-btn>
                </v-card-actions>

            </div>

        </div>
    </div>
</template>
<script>
export default {
    name: "Product",
    props: ['product', 'edit'],
    data() {
        return {
            lowestPrice: 0,
            highestPrice: 0,
        }
    },
    methods: {
        deleteProduct(){
            this.$store.dispatch('deleteProductFromStore', this.product.id );
        },
        addProductToCart(){
            this.$store.dispatch('cartStore/addToCart', { id: this.getProduct.id, total_quantity: 1, variants: {medium: this.selectedMedium.id, size: this.selectedSize[0], quantity: this.quantity}});
            // this.$store.dispatch('cartStore/addToCart', this.product.id );
        }

    },
    computed: {
        isAdmin(){
            return this.$store.state.isAdmin;
        },
    },
    created: function(){
        let low = 100000000;
        let high = 0;
        for (var k in this.product.variants ){
            for ( var i in this.product.variants[k].sizes ){
                if ( this.product.variants[k].sizes[i] < low ){ // find the low
                    low = this.product.variants[k].sizes[i];
                }
                if ( this.product.variants[k].sizes[i] > high ){
                    high = this.product.variants[k].sizes[i];
                }
            }
        }
        this.lowestPrice = low;
        this.highestPrice = high;
    }
    
}
</script>
<style>

.product-card {
    /* background-color: rgba(255, 255, 255, 0.034) !important; */
    /* -webkit-backdrop-filter: blur(10px); */
    /* backdrop-filter: blur(10px); */
    /* border-radius: 20px; */
    /* border-radius: .5em; */
    height: 400px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    position: relative;
}


.card-image-container {
    /* border-radius: .5em .5em 0 0; */
    /* height: 100%; */
    width: 100%;
    overflow: hidden;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.card-image {
    width: 100%;
    /* border-radius: .5em .5em 0 0; */
    height: 100%;
    object-fit: cover;
    transition: transform .5s ease;
}

.card-image-container:hover .card-image {
    transform: scale(1.03);
}

/* XS */
@media (max-width: 600px){
    .product-card {

    }
}

/* SM */
@media (min-width: 601px) and (max-width: 960px){
}

/* MD */
@media (min-width: 961px) and (max-width: 1264px){
    
}

/* LG */
@media (min-width: 1265px) and (max-width: 1904px){
    
}

/* XL */
@media (min-width: 1905px){
   
}
    
</style>