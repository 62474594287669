<template>
<div class="fill-height grey darken-4 py-16">
    <v-container class="py-16">
        <!-- <div class="blue-border">Cart Page</div> -->
        <v-row class="full-width mx-0 mt-16" justify="center" align="start">
            <div v-if="getProductsInCart.length === 0" class="text-h4 smoke--text">Your Cart Is Empty</div>
            <v-col v-else  xs="12" sm="10" md="6" lg="6" xl="6" class="cart-page-background glass-background glass-border rounded-lg">
                
                <!-- <v-row justify="start" align="center" class="full-width mx-0" > -->
                    <!-- <v-col v-for="p in getProductsInCart" :key="p.product.id" xl="3" lg="4"> -->
                        <CartItem v-for="(product, i) in getProductsInCart" :key="i" :product="product" :isLast="i === getProductsInCart.length - 1" class="my-2 py-0" @increment-quantity="incrementQuantity" @decrement-quantity="decrementQuantity">
                        </CartItem>
                    <!-- </v-col> -->
                <!-- </v-row> -->
                <div class="text-right text-h6 text-md-h4">Total: ${{totalCostOfCart}}</div>
            </v-col>
        </v-row>
        <v-row v-if="getProductsInCart.length > 0" class="mx-0 mt-10" justify="center" align="center">
            <v-col xs="12" sm="10" md="6" lg="6" xl="6" class="cart-page-background glass-background glass-border rounded-lg d-flex flex-row justify-end">
                <div class="buy-button action-button mr-lg-6" @click="cartCheckout()">
                    <div class="d-flex justify-center align-center text-subtitle font-weight-medium">
                        Checkout
                    </div>
                </div>
            </v-col>
        </v-row>
    </v-container>
</div>
</template>

<script src="https://js.stripe.com/v3/"></script>
<script>
import CartItem from "../components/CartItem.vue";
export default {
    name: "Cart",
    components: {
        CartItem,
    },
    data() {
        return {
            stripe: Stripe(process.env.VUE_APP_STRIPE_TEST_KEY),
        }
    },
    methods: {
        incrementQuantity(event) {
            console.log("increment event", event);
            this.$store.dispatch('cartStore/addToCart', event );
        },
        decrementQuantity(event) {
            console.log("decrement event", event);
            this.$store.dispatch('cartStore/removeItemFromCart', event);
        },
        async cartCheckout(){
            this.$store.dispatch('cartStore/cartCheckout')
            .then((sessionId) => {
                if(sessionId === null){
                    // TODO: let user know we couldn't create session Id!
                    console.log("couldn't successfully create the session Id. Let user know.")
                }
                console.log("In cart.vue the session to start with stripe is: ", sessionId);
                this.stripe.redirectToCheckout({ sessionId: sessionId })
            })
            .then((result)=> {
                console.log("the result is: ", result);
                // If `redirectToCheckout` fails due to a browser or network
                // error, you should display the localized error message to your
                // customer using `error.message`.
                if(result.error){
                    console.log(result.error.message);
                }
                else {
                    console.log("clear the cart!!!");
                    this.$store.commit('clearCart');
                }
            })
            .catch((err) => {
                console.log("ERROR WITH STRIPE CHECKOUT: ", err);
            })  
        }

    },
    beforeCreate(){
        this.$store.commit('changeNavbarColor', "transparent");
        this.$store.commit('changeNavbarApp', false);
        this.$store.commit('changeNavbarGlass', true);
    },
    mounted(){
        console.log("the products in the cart are: ", this.getProductsInCart)
    },
    computed: {
        getProductsInCart(){
            return this.$store.getters['cartStore/productsInCart'];
        },
        totalCostOfCart(){
            return this.$store.getters['cartStore/totalCostOfCart'].toFixed(2);
        }
    },
}
</script>

<style>
    .cart-page-background {
        /* background-color: rgb(89, 3, 122); */
        /* background-image: linear-gradient(180deg, rgba(66,66,66,1) 0%, var(--v-warning-base) 50%, rgba(66,66,66,1) 100%); */
        background-repeat: repeat-y;
    }
    
</style>