import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import 'vuetify/dist/vuetify.min.css'
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
          light: {
            primary: '#FFFFFF',
            secondary: colors.grey.lighten5,
            accent: colors.grey.darken4,
            success: colors.green.lighten1,
            warning: colors.red.darken4,
            info: colors.grey.darken1,
            smoke: colors.grey.lighten4,
            buy: colors.yellow.darken2,
            // action:
            // primary: '#183642',
            // secondary: '#F0BC42',
            // accent: '#3C6090',
            // success: colors.green.lighten1,
            // warning: '#ED3F1D',
            // info: '#1F2233',
            // smoke: '#f5f5f5',
          }

          // light: {
          //   primary: colors.orange.darken1,
          //   secondary: colors.deepOrange.darken2,
          //   accent: colors.grey.darken3,
          //   info: colors.white,
          //   success: colors.blueGrey.darken1,
          //   warning: colors.deepOrange.darken4,
          //   proceed: colors.green.lighten1,
          //   smoke: #f5f5f5,
          // },

          // dark: {
          //   primary: '#61892F',
          //   secondary: '#565657',
          //   accent: '#FFC107',
          //   info: '#000000',
          //   success: '#4CAF50',
          //   warning: '#FFC107',
          // },
        },
        options: {
          customProperties: true
        }
      },
});
