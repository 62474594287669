<template>
<div class="full-height full-width">
    <div class="parallax-container">
        <div class="parallax-header black" :class="[ backgroundImageIndex % 2 === 1 ? 'ken-burns-out' : 'ken-burns-in' ]" :style="{ backgroundImage: `url(${headerBackgroundImage})`}"></div>
    </div> 

        <div class="full-width header-content-wrapper">
        
            <div class="header-hero-text-wrapper mb-12">
                <div class="white--text font-weight-light text-md-h1 text-h2">dillan.photo</div>
            </div>

            <div >
                <router-link class="text-decoration-none mb-4" to="/prints"><v-btn large class="rounded-0 px-3 text-overline glass-background-4" outlined color="white">Shop Prints</v-btn></router-link>           
            </div>
           
        </div>

         <div class="header-products-preview-wrapper d-flex d-flex justify-center full-width px-0 mt-8 py-2">
                <v-col xl="9" lg="9" xs="10" class="px-0">

                    <div class="full-width text-h3 text-left mb-4 px-3">
                        Best Sellers
                    </div>

                    <div justify="center" align="center" class="featured-products">
                        <template v-if="featuredProducts.length > 0">
                            <v-col v-for="product in featuredProducts" :key="product.id" cols="12" xl="3" lg="3" md="4" sm="5" xs="6">
                                <ProductCard :edit="false" :product="product"></ProductCard>
                            </v-col>
                        </template>

                        <template v-else>
                            <v-col v-for="i in 4" :key="i" cols="12" xl="3" lg="4" md="4" sm="5" xs="6">
                                <div class="product-card">
                                    <div class="card-image-container glass-background"></div>
                                    <v-card-actions class="justify-end mb-2 mr-2">
                                        <div class="buy-button"></div>
                                        <div class="add-to-cart-button"></div>
                                    </v-card-actions>
                                </div>
                            </v-col>
                        </template>

                    </div>
                    
                </v-col>
            </div>


</div>
</template>
<script>
import firebase from "../../firebaseConfig.js";
import ProductCard from "../components/ProductCard.vue";
export default {
    name: "Home",
    components:{
        ProductCard,
    },
    data() {
        return {
            headerBackgroundImage: "",
            backgroundImageIndex: 0,
            headerImages: [
                require("../assets/gallery/snow_canyon_sunrise.jpg"),
                require("../assets/gallery/valley_of_fire2.jpg"),
                require("../assets/gallery/beach_costa_rica_tropical.jpg"), 
                require("../assets/gallery/beach_costa_rica.jpg"), 
                require("../assets/gallery/snowy_mountains_utah.jpg"), 
                require("../assets/gallery/bryce_sunrise.jpg"),
                require("../assets/gallery/doom_clouds.jpg"),
                require("../assets/gallery/halibut_cove.jpg"),
                require("../assets/gallery/virgin_river.jpg"), 
            ],
            featuredProducts: [
                // { id: 0, name: "Product", src: require("../assets/gallery/valley_of_fire.jpg") },
                // { id: 1, name: "Product 2", src: require("../assets/gallery/kenai_lake.jpg") },
                // { id: 2, name: "Product 3", src: require("../assets/gallery/air_balloon_2.jpg") },
                // { id: 3, name: "Product 4", src: require("../assets/gallery/bryce_sunrise_2.jpg") },
            ],
            featuredProductsRef: firebase.db.collection("products").orderBy("purchased").limit(4),
            featuredProductsEmpty: false,
            changeInterval: null,
        }
    },
    methods: {
        getFeaturedProducts(){
            this.featuredProductsRef.get()
                .then((snapshot) => {
                    if(snapshot.size <= 0){
                        return;
                    }
                    snapshot.forEach((doc) => {
                        this.featuredProducts.push(doc.data());
                    })
                })
                .catch((error) => {
                    this.featuredProductsEmpty = true;
                    console.error("error retrieving the featured products", error);
                })
        },
        changeBackgroundImage(){
            /* check if index is within range, if not start the slide show over */
            if (this.backgroundImageIndex < this.headerImages.length - 1){
                this.backgroundImageIndex += 1;
            }
            else {
                this.backgroundImageIndex = 0;
            }
            // console.log("index value: ", this.backgroundImageIndex);
            this.headerBackgroundImage = this.headerImages[this.backgroundImageIndex];
        }

    },
    beforeCreate(){
        this.$store.commit('changeNavbarColor', "transparent");
        this.$store.commit('changeNavbarApp', false);
        this.$store.commit('changeNavbarGlass', false);
    },
    created(){
        this.headerBackgroundImage = this.headerImages[this.backgroundImageIndex];
        this.getFeaturedProducts();
    },
    mounted(){
        this.changeInterval = setInterval( this.changeBackgroundImage, 20000 );
    },
    beforeDestroy(){
        clearInterval(this.changeInterval);
    }
}
</script>

<style scoped>

.parallax-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 90%;
    overflow: hidden;
}
.parallax-header {
    /* background-image: url("../assets/gallery/valley_of_fire.jpg"); */
    height: 100%;
    max-height: 100%;
    width: 100%;
    max-width: 100%;
    background-color: black;
    background-attachment: fixed;
    background: no-repeat 50% 50%;
    background-position: center;
    /* background-repeat: no-repeat; */
    background-size: cover;
    overflow-y: hidden;
    position: absolute;
}

.ken-burns-in {
    /* animation: kenburns-in 20s infinite; */
    /* animation-timing-function: ease-in; */
    /* transform: scale3d(1.2, 1.2, 1.2); */
}

.ken-burns-out {
    /* animation: kenburns-out 20s infinite; */
    /* animation-timing-function: ease-in; */
}


.header-content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 90%;
}

.header-hero-text-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    z-index: 2;
}

.header-products-preview-wrapper {
    z-index: 7;
    background-color: rgba(255, 255, 255, 0.1) !important;
    -webkit-backdrop-filter: blur(12px);
    backdrop-filter: blur(12px);
}

.featured-products {
    overflow-x: scroll;
    display: flex;
    flex-direction: flex-row;
    justify-content: flex-center;
    align-items: space-between;
    /* flex-wrap: nowrap;  */
}


 @keyframes kenburns-out {
     0%{
         opacity: 0;
     }
     5%{
         opacity: 1;
     }
     95% {
         /* transform: scale3d(1.15, 1.15, 1.15) translate3d(-140px, -80px, 0px); */
         transform: scale3d(1.2, 1.2, 1.2) /*translate3d(0, 100px) */;
         animation-timing-function: ease-in;
         opacity: 1;
     }
     100%{
         /* transform: scale3d(2,2,2) translate3d(-120px, -60px, 0px); */
         transform: scale3d(2, 2, 2);
         opacity: .2;
     }
}

 @keyframes kenburns-in {
     0%{
         opacity: 0;
     }
     5%{
         opacity: 1;
     }
     95% {
         /* transform: scale3d(1.15, 1.15, 1.15) translate3d(-140px, -80px, 0px); */
         transform: scale3d(1, 1, 1) /*translate3d(0, 100px) */;
         animation-timing-function: ease-in;
         opacity: 1;
     }
     100%{
         /* transform: scale3d(2,2,2) translate3d(-120px, -60px, 0px); */
         transform: scale3d(2, 2, 2);
         opacity: .2;
     }
}




</style>