<template>
    <v-container>
        <!-- <div>Cart Item</div> -->
        <!-- <v-col cols="12" xl="6" lg="6"> -->
            <div class="full-width cart-item-card glass-border rounded-xl grey--text text--darken-4">
                <v-row justify="center" align="center" class="mx-0 full-width">
                    <v-col xl="6" lg="5" md="4" sm="3" xs="3" class="">
                        <img class="card-item-image" style="border-radius: .5em 0 0 .5em;" :src="getProductById.images.showcase.lg" contain />
                    </v-col>
                    <v-col  xl="6" lg="5" md="4" sm="3" xs="6" class="py-11">
                        <div class="text-subtitle-1 text-md-h6">{{getProductById.name}}</div>
                        <div class="text-caption text-md-subtitle-1">{{getProductById.type}}</div>
                        <div class="text-caption text-md-subtitle-1">{{ productVariantMaterial}} - {{product.variants.size}}</div>
                        <!-- <div class="text-subtitle-1">{{product.variants.size}}</div> -->
                        <div class="mt-2 d-flex flex-column">
                            <div class="text-caption">Quantity:</div>
                            <div class="d-flex flex-row justify-start">
                                <v-icon small @click="$emit('decrement-quantity', { id: product.id, medium: product.variants.medium, size: product.variants.size } )">mdi-minus</v-icon>
                                <div class="rounded mx-2 px-3 quantity-input">{{product.total_quantity}}</div>
                                <v-icon small @click="$emit('increment-quantity', { id: product.id, variants: product.variants, total_quantity: 1 })">mdi-plus</v-icon>
                            </div>
                            <!-- <v-text-field outlined dense :readonly="true" prepend-icon="mdi-minus" append-outer-icon="mdi-plus" label="Quantity" @click:prepend="$emit('decrement-quantity', product.id)" @click:append="$emit('increment-quantity', product.id)">{{p.quantity}}</v-text-field> -->
                        </div>
                        <div class="text-caption font-weight-bold font-weight-md-regular text-md-h6">Total: ${{priceForItem}} </div>
                    </v-col>
                </v-row>
            </div>
            <v-divider v-if="!isLast" class="accent--text my-2 grey lighten-1" ></v-divider>

        <!-- </v-col> -->
    </v-container>
</template>

<script>
export default {
    name: "CartItem",
    props: [ 'product', "isLast" ],
    data() {
        return {

        }
    },
    methods: {

    },
    mounted(){
        console.log("this.product in cartItem: ", this.product)
        // console.log("getProductById() ", this.getProductById);
    },
    computed: {
        productVariantMaterial(){
            return this.$store.getters.productVariantMaterialById(this.product.variants.medium);
        },
        getProductById(){
            return this.$store.getters.productById(this.product.id);
        },
        priceForItem(){
            const price = this.product.variants.price * this.product.total_quantity;
            // const price = this.$store.getters.getPriceForProduct(this.product.variants.medium, this.product.variants.size) * this.product.quantity;
            return price.toFixed(2);
            // return Math.round( (this.$store.getters.getPriceForProduct(this.product.variants.medium, this.product.variants.size) * this.product.quantity) * 1000) / 1000;
            // return this.$store.getters.getPriceForProduct(this.product.variants.medium, this.product.variants.size);
        },
       
    },
}
</script>

<style scoped>
.cart-item-card {
    background-color: rgba(255, 255, 255, 0.034) !important;
    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);
    /* border-radius: 20px; */
    border-radius: .5em;
    /* height: 300px; */
    display: flex;
}

.card-item-image{ height: 200px; }

    /* XS */
@media (max-width: 600px){
   .card-item-image{ height: 70px; }

}

/* SM */
@media (min-width: 601px) and (max-width: 960px){
    .card-item-image{ height: 200px; }
}

/* MD */
@media (min-width: 961px) and (max-width: 1264px){
    .card-item-image{ height: 200px; }
}

/* LG */
@media (min-width: 1265px) and (max-width: 1904px){
    .card-item-image{ height: 200px; }
}

/* XL */
@media (min-width: 1905px){
    
}
</style>