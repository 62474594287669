<template>
    <v-app>
        <!-- <Navbar/> -->
        <div v-if="loadingState" class="loading-container ma-0 pa-0">
            <v-overlay absolute>
                <v-progress-circular size="50" indeterminate color="secondary"></v-progress-circular>
            </v-overlay>
        </div>
        <router-view />
    </v-app>
</template>

<script>
import firebase from '../firebaseConfig.js'
// import Navbar from "./components/Navbar.vue";
export default {
    components:{
        // Navbar
    },
    data() {
        return {
            
        }
    },
    methods: {

    },
    mounted(){
        firebase.auth.onAuthStateChanged(async() => { 
            if (firebase.auth.currentUser){
                if(firebase.auth.currentUser.uid === process.env.VUE_APP_ADMIN_ID){
                    this.$store.dispatch('giveAdminPermissions', true);
                }
                else {
                    this.$store.dispatch('giveAdminPermissions', false);
                }
            }
            else {
                this.$store.dispatch('giveAdminPermissions', false);
            }
        });
    },
    created(){
        // retrieve all products on creation
        this.$store.dispatch('getProductVariants');
        this.$store.dispatch('getAllProducts');
        fetch('https://www.udemy.com/api-2.0/courses/746432/?fields[course]=title,headline,completion_ratio', {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json;charset=utf-8',
          // 'Authorization': 'Basic gLaEED3SvPX1h3YlS8DqzCSgBHLa1VoqiiHGAKBKDIkli9hYzB6HDYdlXiZbjHaksDBI6Ss3eCfKA0eZJUyLOR13H7CBANZC4iFYTOo4lKuebvW4zsa7f31yjAKvwM82, Bearer DKhQtqjdqaE3YTyQAkuZCoBlsxCcA4lkLfCiA5vj',
          'Authorization': 'Basic REtoUXRxamRxYUUzWVR5UUFrdVpDb0Jsc3hDY0E0bGtMZkNpQTV2ajpnTGFFRUQzU3ZQWDFoM1lsUzhEcXpDU2dCSExhMVZvcWlpSEdBS0JLRElrbGk5aFl6QjZIRFlkbFhpWmJqSGFrc0RCSTZTczNlQ2ZLQTBlWkpVeUxPUjEzSDdDQkFOWkM0aUZZVE9vNGxLdWVidlc0enNhN2YzMXlqQUt2d004Mg==',
        }
      })
      .then((response) => {
        response.json().then((res) => {
          console.log("udemy res is ", res);
        }).catch((err) => {
          console.log("err ", err)
        })
      })
      .catch((err) => {
        console.log("err 2 is ", err)
      })

    },
    computed: {
        loadingState() {
            return this.$store.state.showLoader;
        },
    }
}
</script>

<style>
.loading-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
}
</style>


