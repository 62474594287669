<template>
    <div class="fill-height view-product-background">
        <v-container class="py-16">
            <v-row class="py-16" justify="center" align="center">
                <v-col cols="12" xl="6" >
                    <v-row>
                        <div @click="$router.go(-1)" class="ml-3 d-flex align-center white--text" style="cursor: pointer;">
                            <v-icon small color="white">mdi-arrow-left</v-icon>
                            Back
                        </div>
                    </v-row>
                    <div class="glass-background-1 glass-border rounded-lg d-flex flex-column justify-space-between pa-10 mt-8">
                        <v-row class="full-width full-height mx-0 justify-space-between">
                            <v-col cols="12" xl="6" class="d-flex flex-column justify-space-between glass-background-2 rounded-lg full-height">
                                <v-skeleton-loader v-if="!getProduct.id" type="image@2"></v-skeleton-loader>
                                <v-img v-else-if="imageSource === null" :src="getProduct.images.display.md" :alt="getProduct.name" contain></v-img>
                                <v-img v-else :src="imageSource" :alt="getProduct.name" contain></v-img>

                                 <v-row justify="start" align="end" no-gutters class="full-width mx-0 ml-n2">
                                    <v-col v-for="(image, i) in getProduct.images" :key="i" cols="2" class="pl-0">
                                        <!-- <span>{{image.lg}} <br></span> -->
                                        <v-img :src="image.lg" :alt="getProduct.name" :style="image.lg === imageSource ? 'border: solid #f5f5f5 2px !important;' : '' " eager height="80" class="ma-2" style="cursor: pointer;" @click="changeImageSource(image.lg)"></v-img>
                                    </v-col>
                                </v-row>

                            </v-col>

                            <v-col cols="12" xl="4" class="glass-background-3 rounded-lg py-4">
                                <div class="full-height d-flex flex-column align-center justify-space-between">
                                    <div>
                                    <!--  TODO: PUT THIS IN A FORM. THAT WAY WE CAN VALIDATE BEFORE SUBMITTING AND DISABLE BUTTONS IF NOT VALID -->
                                        <div class="text-h4 text-right white--text">{{getProduct.name}}</div>

                                        <div class="text-h6 white--text mt-2 text-right">${{selectedSize ? Number.parseFloat(selectedSize[1] * quantity).toFixed(2) : 0}}</div>

                                        <v-select v-model="selectedMedium" :items="availableMediums" outlined dense color="white" class="white--text mt-4" label="Medium" :item-text="item => item.material" @change="selectedSize = null" return-object item-color="success"></v-select>
                
                                        <v-select v-model="selectedSize" :items="selectedMedium !== null ? sizesOrderedByPrice : []" outlined dense color="white"  class="white--text" label="Size" return-object :item-text="item => item[0] + ' - ' + '$' + item[1]" @change="quantity = 1" item-color="success"></v-select>
                                        
                                        <div class="mt-2 d-flex flex-column align-end">
                                            <div class="text-caption">Quantity:</div>
                                            <div class="d-flex flex-row justify-start">
                                                <v-icon small @click="quantity -= 1">mdi-minus</v-icon>
                                                <!-- <div class="rounded mx-2 px-3 quantity-input">{{p.quantity}}</div> -->
                                                <input v-model="quantity" class="rounded mx-2 px-3 quantity-input" type="number">
                                                    <!-- <span class="input-focus-bg"></span> -->
                                                <v-icon small @click="quantity += 1">mdi-plus</v-icon>
                                            </div>
                                            <!-- <v-text-field outlined dense :readonly="true" prepend-icon="mdi-minus" append-outer-icon="mdi-plus" label="Quantity" @click:prepend="$emit('decrement-quantity', p.product.id)" @click:append="$emit('increment-quantity', p.product.id)">{{p.quantity}}</v-text-field> -->
                                        </div>
                                    </div>
                                   
                                </div>
                            </v-col>
                        </v-row>

                         <!-- BUTTON ACTIONS -->
                        <div class="full-width d-inline-block d-flex flex-row justify-end red-border mt-16 mx-0">
                            <div class="add-to-cart-button action-button mr-6" @click="addProductToCart()">
                                <div class="d-flex justify-center align-center text-subtitle font-weight-medium">
                                    ADD TO CART
                                </div>
                            </div>

                            <div class="buy-button action-button" @click="buyProduct()">
                                <div class="d-flex justify-center align-center text-subtitle font-weight-medium">
                                    BUY
                                </div>
                            </div>
                        </div>

                        <!-- <v-row class="mx-0">
                            <v-col cols="12" xl="6">
                                <v-row justify="start" align="center" no-gutters class="full-width mx-0 ml-n2">
                                        <v-col v-for="image in getProduct.images" :key="image.name" cols="2" class="pl-0">
                                            <v-img :src="image.url" :alt="image.name" :style="image.url === imageSource ? 'border: solid #f5f5f5 2px !important;' : '' " eager height="80" class="ma-2 rounded-lg" style="cursor: pointer;" @click="changeImageSource(image.url)"></v-img>
                                        </v-col>
                                </v-row>
                            </v-col>
                        </v-row> -->
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script src="https://js.stripe.com/v3/"></script>
<script>
// import firebase from "../../firebaseConfig.js";
export default {
    name: "ViewProduct",
    props: [],
    data(){
        return {
            stripe: Stripe(process.env.VUE_APP_STRIPE_TEST_KEY),
            imageSource: null,
            selectedMedium: null,
            selectedSize: null,
            availableMediums: [],
            quantity: 1,
        }
    },
    methods:{
        changeImageSource(url){
            this.imageSource = url;
        },
        // getOptionsText(item){
        //     console.log("items text", item);
        //     return item.material;
        // },
        buyProduct(){
            console.log("selectedSize", this.selectedSize)
            this.$store.dispatch('setLoadingState', {on: true});
            // this.$store.dispatch('cartStore/cartCheckout', {on: true});
            // TODO: CHECKOUT FROM STORE!!
            this.$http.post('/create-checkout-session-for-one-product' ,
            {
                sentFrom: window.location.pathname,
                productId: this.getProduct.id,
                mediumId: this.selectedMedium.id,
                size: this.selectedSize[0],
                price: this.selectedSize[1],
                quantity: this.quantity, // TODO - change this to allow user to control the quantity
            }
            ).then((response) => {
                if (response.data.error){
                    console.log("error creating checkout session : ", response.data.error)
                    return;
                }
                this.$store.dispatch('setLoadingState', {on: false});
                return this.stripe.redirectToCheckout({ sessionId: response.data.id })
            })
            .then((result)=> {
                // If `redirectToCheckout` fails due to a browser or network
                // error, you should display the localized error message to your
                // customer using `error.message`.
                if(result.error){
                    console.log(result.error.message);
                }
            })
            .catch((err) => {
                this.$store.dispatch('setLoadingState', {on: false});
                // TODO - send in user alert
                console.log("error creating checkout session: ", err)
            });
        },
        addProductToCart(){
            this.$store.dispatch('cartStore/addToCart', { id: this.getProduct.id, total_quantity: this.quantity, variants: {medium: this.selectedMedium.id, size: this.selectedSize[0], price: this.selectedSize[1],  quantity: this.quantity}});
        },
    },
    computed: {
        getProduct(){
            return this.$store.getters.productById(this.$route.params['id']);
        },
        sizesOrderedByPrice(){
            return Object.entries(this.getProduct.variants[this.selectedMedium.id].sizes).sort((a,b) => a[1] - b[1] );
        }
    },
    mounted: function(){
        // console.log("route", window.location.pathname);
        if(typeof this.getProduct.id === 'undefined'){
            console.log("undefined product")
            // this.getProductById();
        }
        else {
            console.log("product: ", this.getProduct)
            this.imageSource = this.getProduct.images.display.lg;
        }
        for (var k in this.getProduct.variants){
            // console.log(this.getProduct.variants[k]);
            this.availableMediums.push(this.getProduct.variants[k]);
        }

        this.selectedMedium = this.availableMediums[0];
        this.selectedSize = Object.entries(this.availableMediums[0].sizes)[0];
        // this.selectedMedium = this.getProduct.options[0].material;
        // console.log("selected Medium", this.selectedMedium)
    },
    created: function(){
       
    }
}
</script>
<style>
.view-product-background {
    background-image: linear-gradient(180deg, var(--v-primary-base) 0%,  var(--v-info-base) 30%, var(--v-primary-base)  100%);
    background-repeat: repeat-y;
}
    
</style>