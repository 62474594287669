import Vue from 'vue'
import Vuex from 'vuex'
import firebase from '../../firebaseConfig.js'
import CartStore from './cart.js';

import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
var ls = new SecureLS({ isCompression: false });

Vue.use(Vuex)


export default new Vuex.Store({
  plugins: [
    createPersistedState({
        paths: ['products', 'productVariants', 'cartStore.cart'],
        storage: {
            getItem: (key) => ls.get(key),
            setItem: (key, value) => ls.set(key, value),
            removeItem: (key) => ls.remove(key),
        },
    }),
  ],
  strict: true,
  state: {
    navbarColor: "transparent",
    navbarApp: false,
    navbarGlass: false,
    userAlert: false,
    userAlertText: "",
    isAdmin: false,
    // ajax loader
    showLoader: false,
    showMobileNav: false,
    // selected product
    // product: {},
    // all products. If there are any they will be loaded into from local storage, but everytime a get request (which we do when the app opens) is made to get more products then it will save those newly received ones there
    products: [], 
    // all product Variants
    productVariants: {},

  },
  mutations: {
    setUserAlert(state, payload){
      state.userAlert = payload.on;
      state.userAlertText = payload.text;
    },
    setAdmin(state, payload){
      state.isAdmin = payload;
    },
    setLoader(state, payload){
      state.showLoader = payload;
    },
    setProducts(state, payload){
      state.products = payload;
      state.showLoader = false;
    },
    clearProducts(state){
      state.products = [];
    },
    addProductToProducts(state, payload){
      state.products.push(payload);
    },
    deleteProduct(state, payload){
      state.products = state.products.filter( p => p.id !== payload.product.id);
    },
    setProductVariants(state,payload){
      state.productVariants = payload;
    },
    changeNavbarColor(state, payload){
      state.navbarColor = payload;
    },
    changeNavbarApp(state, payload){
      state.navbarApp = payload;
    },
    changeNavbarGlass(state, payload){
      state.navbarGlass = payload;
    },
    setMobileNavState(state, payload){
      state.showMobileNav = payload;
    }
  },
  actions: {
    alertUser({commit}, payload){
      commit('setUserAlert', payload);
    },
    setLoadingState({commit}, payload){
      commit('setLoader', payload.on);
    },
    giveAdminPermissions({commit}, payload){
      commit('setAdmin', payload);
    },
    getAllProducts({commit}){
      commit('setLoader', true);
      let allProductsRef = firebase.db.collection("products").orderBy("dateCreated");
      allProductsRef.get()
        .then((snapshot) => {
          if (snapshot.size === 0){
            commit('setLoader', false);
            return;
          }
          commit('clearProducts');
          snapshot.forEach((doc) => {
            commit('addProductToProducts', doc.data());
          })
        })
        .catch((err) => {
          console.error("Error retrieving products", err.message);
        })
      commit('setLoader', false);
    },
    getProductVariants({commit}){
      commit('setLoader', true);
      const productVariantRef = firebase.db.collection("variants").orderBy("name");
      productVariantRef.get()
      .then((snapshot) => {
        if (snapshot.empty){
          commit('setLoader', false);
          return;
        }
        let variants = {};
        snapshot.forEach((doc) => {
          variants[doc.id] = doc.data();
        })
        commit('setProductVariants', variants);
      })

      commit('setLoader', false);
    },
    async deleteProductFromStore({commit}, payload){
      console.log("Product to delete: ", payload.product.id);
      /* delete the product images from the firebase storage */
      let storageRef = firebase.storage.ref();
      for( var i = 0; i < payload.product.images.length; i++ ){
        let productImageRef = storageRef.child(`productImages/${payload.product.id}/${payload.product.images[i].name}`);
        await productImageRef.delete(payload.product.images[i])
          .then(() => {
              console.log("deleted image successfully");
          })
          .catch((err) => {
              console.error("Error Deleting images: ", err);
              commit('setUserAlert', {on: true, text: `Error Deleting the product images: ${payload.product.id}`});
          })
      }
      /* delete the product from firebase firestore */
      await firebase.db.collection("products").doc(payload.product.id).delete()
        .then(() => {
          console.log("successfully deleted the product");
        })
        .catch((err) => {
          console.log("error removing the product document", err);
          commit('setUserAlert', {on: true, text: `Error Deleting the product: ${payload.product.id}`});
        })
      /* delete the product from the state || re-request the products! */
      commit('deleteProduct', payload);
      commit('setUserAlert', {on: true, text: `Successfully Deleted The Product: ${payload.product.id}`});
    },
    // TODO: MAKE A METHOD TO FETCH A CHECKOUT SESSION BY ID!
  },
  getters: {
    // get all products
    allProducts: (state) => {
      return state.products;
    },
    // get landscape products
    landscapeProducts: (state) => {
      if (state.products.length > 0){
        return state.products.filter( p => p.type === 'Landscape');
      }
      return [];
    },
    // get portrait products
    portraitProducts: (state) => {
      if (state.products.length > 0){
        return state.products.filter( p => p.type === 'Portrait');
      }
      return [];
    },
    // get pano products
    panoramicProducts: (state) => {
      if (state.products.length > 0){
        return state.products.filter( p => p.type === 'Panoramic');
      }
      return [];
    },
    // get product by id 
    productById: (state, getters) => id => {
      if ( getters.allProducts.length > 0){
        return getters.allProducts.filter( p => p.id === id)[0]
      }
      else {
        return state.product;
      }
    },
    productVariantMaterialById: (state) => id =>{
      // console.log("ID", id);
      // console.log(state.productVariants[`${id}`])
      return state.productVariants[`${id}`].name;
    },
    // gets all the product variants that can be created with a product as an array
    productVariantMaterials: (state) => {
      if( Object.keys(state.productVariants).length > 0){
        return Object.values(state.productVariants);
      }
      return [];
    },
    // will get the price for a product with a select medium and size
    getPriceForProduct: (state) => (id, size) => {
      // console.log("size",size);
      // console.log("medium id", id);
      // console.log("state", state);
      if(typeof state.productVariants[`${id}`] !== 'undefined'){
        if(typeof state.productVariants[`${id}`].sizes[`${size}`] !== 'undefined'){
          return state.productVariants[`${id}`].sizes[`${size}`];
        }
      }
      return null;
    },
    // gets all the possible sizes we can create with a product
    getProductVariantSizes: (state) => id => {
      if (Object.keys(state.productVariants).length > 0 && typeof id !== 'undefined'){
        return Object.keys(state.productVariants[id].sizes);
      }
      return [];
    }
    

  },
  modules: {
    cartStore: CartStore
  }
})
