import "@babel/polyfill";
import Vue from "vue";
import App from "./App.vue";
import router from "./router/router.js";
import store from "./store";
import firebase from '../firebaseConfig.js'
import vuetify from './plugins/vuetify';
import axios from 'axios';

 
require('./styles/main.css'); // custom css file


Vue.config.productionTip = false;
Vue.prototype.$http = axios;

axios.defaults.baseURL = "https://dillanjohnsonphoto.herokuapp.com";
// axios.defaults.baseURL = "http://localhost:8000";
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.put['Content-Type'] = 'application/json';

let app;
firebase.auth.onAuthStateChanged(() => {
    if (!app){
      app = new Vue({
        router,
        store,
        vuetify,
        render: h => h(App)
      }).$mount("#app");
    }
});

