<template>
<div class="prints-background fill-height">
    <v-container class="py-16">
        <!-- <v-row class="py-16">
            <v-snackbar v-model="userAlert" color="white--text" centered top absolute app timeout="-1" style="z-index: 50;" transition="slide-y-reverse-transition">
                {{userAlertText}}
                <template v-slot:action="{ attrs }">
                    <v-btn
                    color="text--primary"
                    text
                    v-bind="attrs"
                    @click="$store.dispatch('alertUser', { on: false, text: ''})"
                    >
                    Close
                    </v-btn>
                </template>
            </v-snackbar>
        </v-row> -->

        <router-view class="py-md-16"></router-view>
    </v-container>
</div>
</template>

<script>
export default {
    // name: "Admin",
    components: {
        // Navbar,
    },
    data() {
        return {

        }
    },
    methods: {

    },
    // computed: {
    //     userAlert(){
    //         return this.$store.state.userAlert;
    //     },
    //     userAlertText(){
    //         return this.$store.state.userAlertText;
    //     }
    // },
}
</script>