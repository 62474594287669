<template>
<div class="full-height full-width">
   <Navbar class="hidden-sm-and-down" :navItems="navItems"/>
   <v-main class="full-height full-width pa-0" >
       <v-snackbar v-if="snackbar" v-model="snackbar" color="white--text" centered top app timeout="3000" style="z-index: 50;" transition="slide-y-reverse-transition">
            {{userAlertText}}
            <template v-slot:action="{ attrs }">
                <v-btn
                color="text--primary"
                text
                v-bind="attrs"
                @click="$store.dispatch('alertUser', { on: false, text: ''})"
                >
                Close
                </v-btn>
            </template>
        </v-snackbar>
    <!-- <v-container class="pa-0 ma-0"> -->
        <!-- <transition :name="transitionName"> -->
            <router-view class="pt-0 pt-md-16 full-width" />
        <!-- </transition> -->
    <!-- </v-container> -->

    <MobileNav class="hidden-md-and-up" @changing-route="setChangingRoute" :navItems="navItems" />
   </v-main>
</div>
</template>

<script>
import Navbar from "./Navbar.vue";
import MobileNav from "./MobileNav.vue";
export default {
    name: "Dashboard",
    components:{
        Navbar,
        MobileNav
    },
    data() {
        return {
            transitionName: "",
            snackbar: this.userAlert,
            navItems: [
                { text: "Home", url: "/" },
                { text: "Gallery", url: "/gallery" },
                { text: "Store", url: "/prints" },
                { text: "Contact", url: "/contact" },
                { text: "Cart", url: "/cart" },
            ],
            scrollY: 0,
            changingRoute: false,
        }
    },
    methods: {
        // noScroll(){
        //     if(this.routeOnMobileNavOpen === this.$route.path){
        //         window.scrollTo(0,this.scrollY);
        //         console.log("scrolling to that pos!");
        //     }
        // },
        setChangingRoute(payload){
            console.log(payload)
            this.changingRoute = payload;
        }
    },
    watch:{
        '$route' (to, from){
            const toDepth = to.path.split('/').length;
            const fromDepth = from.path.split('/').length;
            this.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left';
            this.changingRoute = false;
        },
        '$store.state.userAlert' (newValue){
            // console.log("user alert watcher: ", newValue, oldValue);
            this.snackbar = newValue;
        },
        'snackbar' (newValue){
            // console.log("snackbar val: ", newValue);
            if(newValue === false){
                this.$store.dispatch('alertUser', { on: false, text: ''})
            }
        },
        'showMobileNav' (newValue){
            // console.log("new Value = ", newValue);
            if(newValue){
                // this.routeOnMobileNavOpen = this.$route.path;
                this.scrollY = window.scrollY;
                const body = document.body;
                body.style.position = "fixed";
                body.style.top = `${this.scrollY * -1}px`;
                body.style.right = 0;
                body.style.left = 0;
                /* 
                console.log("scroll y on open", this.scrollY)
                const body = document.body;
                body.style.position = "fixed";
                body.style.top = this.scrollY;
                body.style.overflow = "hidden";
                console.log("route ", this.$route);
                body.style.height = "100vh";
                body.style.paddingRight = "15px";
                */

            }
            else {
                const body = document.body;
                body.style.top = '';
                body.style.left = '';
                body.style.right = '';
                body.style.position = '';
                if (!this.changingRoute){
                    // console.log("scrolling to the saved location");
                    window.scrollTo(0, this.scrollY);
                }
                /*
                const body = document.body;
                console.log("scroll Y on false", this.scrollY)
                body.style.overflow = "auto";
                body.style.height = '';
                body.style.position = '';
                body.style.top = '';
                window.scrollTo(0, parseInt(this.scrollY || '0') * -1);
                */
            }
        }
    },
    computed: {
        userAlert(){
            return this.$store.state.userAlert;
        },
        userAlertText(){
            return this.$store.state.userAlertText;
        },
        showMobileNav(){
            return this.$store.state.showMobileNav;
        }
    },
}
</script>

<style scoped>
.router-wrap {
    height: 100%;
    width: 100%;
}
</style>