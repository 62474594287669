import Vue from "vue";
import VueRouter from "vue-router";
import firebase from '../../firebaseConfig'

import Home from "../pages/Home.vue";
import Dashboard from "../components/Dashboard.vue";
import Cart from "../pages/Cart.vue";
import Prints from "../pages/Prints.vue";
import Gallery from "../pages/Gallery.vue";
import ViewProduct from "../pages/ViewProduct.vue";
import OrderComplete from "../pages/OrderComplete.vue";

// admin components
import Index from "../pages/admin/Index.vue";
import Login from "../pages/admin/Login.vue";
import New from "../pages/admin/New.vue";
import Products from "../pages/admin/Products.vue";
import Edit from "../pages/admin/Edit.vue";

import goTo from 'vuetify/es5/services/goto'


Vue.use(VueRouter);

const routes = [
  {
    path: "",
    component: Dashboard,
    children: [
      {
        path: "",
        redirect: "home",
        component: Home
      },
      {
        path: "home",
        name: "Home",
        component: Home
      },
      {
        path: "/prints",
        name: "PrintStore",
        component: Prints
      },
      {
        path: "/order/success",
        name: "OrderComplete",
        component: OrderComplete
      },
      {
        path: "/gallery",
        name: "Gallery",
        component: Gallery
      },

      {
        path: "/admin",
        name: "Admin",
        component: Index,
        children: [
          {
            path: "/admin/login",
            name: "login",
            component: Login,
          },
          {
            path: "/admin/new-product",
            name: "New",
            component: New,
            meta: { requiresAuth: true },
          },
          {
            path: "/admin/products",
            name: "Products",
            component: Products,
            meta: { requiresAuth: true },
          },
          {
            path: "/admin/edit/:id",
            name: "Edit",
            component: Edit,
            meta: { requiresAuth: true },
          }
        ]
      },
      {
        path: "/cart",
        name: "Cart",
        component: Cart,
      },
      {
        path: "/product/:id",
        name: "ViewProduct",
        component: ViewProduct
      },
    ] // dashboard children. everything that needs a navbar should be in this.
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;
    if (to.hash){
      scrollTo = to.hash
    } else if ( savedPosition ){
      scrollTo = savedPosition.y;
    }
    return goTo(scrollTo);
  }
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const currentUser = firebase.auth.currentUser;
  if (requiresAuth && !currentUser) {
      next('/admin/login')
  }
  else {
    // if(to)
    next()
  }
});

export default router;

// route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () =>
      // import(/* webpackChunkName: "admin" */ "@/pages/Admin.vue")