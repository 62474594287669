<template>
<div class="gallery-background full-height full-width py-16">
    <v-container class="py-16">
        <div class="mt-16 pl-2" >
             <!-- TODO: LINK TO YOUR INSTA PAGE HERE -->
             <a href="https://www.instagram.com/dillan.photo/" target="_blank" class="white--text text-decoration-none">
                <v-icon class="white--text">mdi-instagram</v-icon>
                @dillan.photo
            </a>
        </div>
        <v-container>
            <v-row class="justify-center justify-md-start" align="center">
                <v-col cols="12" xl="3" lg="4" md="6" sm="8" xs="12" v-for="(image,index) in imageUrls" :key="image.src" class="justify-center align-center" align-self="center" :class="{ 'vid-player-wrapper' : image.type === 'VIDEO'}">
                    <v-img v-if="image.type === 'IMAGE'" :src="image.src" class="grey darken-2 elevation-3 rounded-lg gallery-item"  @load="imageLoaded(index)" >
                        <!-- TODO: zoom background image and do masonry -->
                    <!-- <v-img :src="image.src" class="accent darken-2" @load="imageLoaded(index)"> -->
                        <!-- <div v-if="!image.loaded"  class="skelly-loader gallery-item"></div> -->
                        <v-skeleton-loader v-if="!image.loaded" class="gallery-item" type="image@3"></v-skeleton-loader>
                    </v-img>
                    <div v-else-if="image.type ==='VIDEO'" class="vid-player-wrapper rounded-lg">
                            <video class="grey darken-2 elevation-3 video rounded-lg gallery-item" :ref="`vidPlayer${index}`" :src="image.src" :poster="image.thumbnail_url" @ended="playerEnded(index)"></video>
                            <v-hover v-slot="{hover}" class="rounded-lg">
                                <div class="vid-player-controls rounded-lg">
                                    <transition name="fade">
                                        <v-btn v-show="image.showControls || hover || !image.playing" x-large v-if="!image.playing" icon @click="playVideo(index)">
                                            <v-icon size="70" class="vid-button">mdi-play</v-icon>
                                        </v-btn>

                                        <v-btn v-show="image.showControls || hover" x-large v-else icon @click="pauseVideo(index)">
                                            <v-icon size="70" class="vid-button">mdi-pause</v-icon>
                                        </v-btn>
                                    </transition>

                                </div>
                        </v-hover>
                    </div>
                    <v-carousel hide-delimiters show-arrows-on-hover :continuous="false" height="550" class="grey darken-2 elevation-3 rounded-lg full-height pa-0 ma-0" v-else-if="image.type === 'CAROUSEL_ALBUM'">
                        <v-carousel-item v-for="(child, i) in image.children" :key="i" :src="child.src" class="gallery-item">
                            <div v-if="i === 0" class="d-flex justify-end">
                                <v-icon right class="white--text mx-5 my-5">mdi-image-multiple-outline</v-icon>
                            </div>
                        </v-carousel-item>
                    </v-carousel>
                    
                </v-col>
                <v-col v-show="loadingImages" cols="12" xl="3" lg="4" md="5" sm="6" xs="12" v-for="i in 10" :key="i">
                    <div class="skelly-loader gallery-item"></div>
                    <!-- <v-skeleton-loader type="image@3" class="accent darken-2 elevation-3 rounded-lg"></v-skeleton-loader> -->
                </v-col>
            </v-row>
            <!-- <v-row v-if="loadingImages" justify="center">
                <v-col cols="3" v-for="i in 10" :key="i">
                    <v-skeleton-loader type="image@3"></v-skeleton-loader>
                </v-col>
            </v-row> -->
        </v-container>
    </v-container>
</div>
</template>

<script>
export default {
    name: "Gallery",
    components: {

    },
    data() {
        return {
            imageUrls: [],
            instagramRequestUrl: "https://graph.instagram.com/me/media?fields=id,caption,children{media_url},media_url,media_type,thumbnail_url&access_token=IGQVJXMGU4bmUybmlfVWx3UmxEU3UzbGFRdjBRVmdBQ2c3a0tRdzhudGJLbHRSR2pfTXk4TEc0NEwtcHFXeTZAvTHM1RENNVmlSSDM3aEl6YWpvZA0ZAiaTZALbUJmQVJOb1hkNXhZAa3plUTRWd2dSRUxjagZDZD",
            nextInstagramRequestUrl: "",
            loadingImages: false,
        }
    },
    methods: {
        getInstagramGallery(url){
            this.$http.get(url)
            // item.media_type === 'CAROUSEL_ALBUM'
            .then((response) => {
                response.data.data.forEach((item) => {
                    // console.log(item.media_type)
                    if (item.media_type === 'CAROUSEL_ALBUM'){
                        this.imageUrls.push({ src: item.media_url, type: item.media_type, loaded: false, thumbnail: item.thumbnail_url, children: [] });
                        item.children.data.forEach((child) => {
                            this.imageUrls[this.imageUrls.length -1].children.push({ src: child.media_url, type: child.media_type, loaded: false, thumbnail: child.thumbnail_url });
                        })
                    }
                    else if (item.media_type === 'VIDEO') {
                        this.imageUrls.push({ src: item.media_url, type: item.media_type, loaded: false, thumbnail: item.thumbnail_url, children: null, playing: false, showControls: true });
                    }
                    else {
                        // console.log("pic")
                        this.imageUrls.push({ src: item.media_url, type: item.media_type, loaded: false, thumbnail: item.thumbnail_url, children: null });
                    }
                })
                if(response.data.paging.next){
                    this.nextInstagramRequestUrl = response.data.paging.next;
                }
                else {
                    this.nextInstagramRequestUrl = null;
                    window.removeEventListener('scroll', this.atBottom); // TODO find out where to place this once there is no more!
                }
                this.loadingImages = false;
                window.addEventListener("scroll", this.atBottom);
            })
            .catch((err) => {
                console.log("error retrieving instagram info: ", err);
            })
        },
        atBottom: function () {
            let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.body.clientHeight; 
            if (bottomOfWindow) {
                if(this.nextInstagramRequestUrl !== null){
                    this.loadingImages = true;
                    this.getInstagramGallery(this.nextInstagramRequestUrl);
                }
            }
        },
        imageLoaded: function(index) {
            // console.log("image has been loaded")
            this.imageUrls[index].loaded = true;
        },
        playVideo(index){
            this.imageUrls[index].showControls = true;
            if(typeof this.$refs[`vidPlayer${index}`] !== 'undefined' && this.$refs[`vidPlayer${index}`].length > 0){
                this.imageUrls[index].playing = !this.imageUrls[index].playing;
                this.$refs[`vidPlayer${index}`][0].play();
            }
            setTimeout( () => {
                console.log("ran timeout")
                this.imageUrls[index].showControls = false;
            }, 1500)
            // console.log(this.$refs[`vidPlayer${index}`]);
        },
        pauseVideo(index){
            this.imageUrls[index].showControls = true;
            if(typeof this.$refs[`vidPlayer${index}`] !== 'undefined' && this.$refs[`vidPlayer${index}`].length > 0){
                this.imageUrls[index].playing = !this.imageUrls[index].playing;
                this.$refs[`vidPlayer${index}`][0].pause();
            }
            setTimeout( () => {
                console.log("ran timeout")
                this.imageUrls[index].showControls = false;
            }, 1500)
        },
        playerEnded(index){
            this.imageUrls[index].showControls = true;
            this.imageUrls[index].playing = false;
        }
    },
    beforeCreate(){
        this.$store.commit('changeNavbarColor', "transparent");
        this.$store.commit('changeNavbarApp', false);
        this.$store.commit('changeNavbarGlass', true);
    },
    created: function() {
        this.loadingImages = true;
        this.getInstagramGallery(this.instagramRequestUrl);
    }

}
</script>
<style scoped>
    .gallery-background {
        background-image: linear-gradient(180deg, var(--v-primary-base) 0%,  rgba(66,66,66,1) 50%, var(--v-primary-base)  100%);
        background-repeat: repeat-y;
    }
    .gallery-item {
        height: 550px !important;
        max-height: 550px;
        min-height: 550px;
        object-fit: cover !important;
    }
    .video {
        height: 80%;
        width: 100%;
    }
    .vid-player-wrapper{
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 90%;
    }
    .vid-player-controls {
        /* top: 50%; */
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
    }
    .vid-button {
        color: rgba(255, 255, 255, 0.664) !important;
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity .7s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }

/* XS */
@media (max-width: 600px){
    .gallery-item { 
        height: 400px !important;
        max-height: 400px;
        min-height: 400px;
    }
}

/* SM */
@media (min-width: 601px) and (max-width: 960px){
   
}

/* MD */
@media (min-width: 961px) and (max-width: 1264px){
  
}

/* LG */
@media (min-width: 1265px) and (max-width: 1904px){
   
}

/* XL */
@media (min-width: 1905px){
   
}
</style>