<template>
<v-container class="py-16 px-16">
    <div class="py-16 px-16">ORDER SUCCESSFUL!</div>
</v-container>
</template>

<script>
export default {
    name: "OrderComplete",
    components: {

    },
    data() {
        return {

        }
    },
    methods: {
        getStripeSession(sessionId){
            if (typeof sessionId == 'undefined' || sessionId === null){
                return;
            }
            this.$http.get('/orders/success', { params: { session_id : sessionId }})
            .then((response) => {
                console.log("the response data is: ", response.data)
                console.log("the response status is: ", response.status);
            })
            .catch((error) => {
                console.log("Error retrieving the order confirmation.", error);
            })

        }

    },
    created(){
        console.log("the session id to retrieve from stripe: ", this.$route.query['session_id'])
        this.getStripeSession( this.$route.query['session_id'] );
    }
}
</script>