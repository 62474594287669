<template >
    <div class="full-width">
        <v-btn class="glass-background" icon x-large :ripple="false" style="z-index: 15; position: fixed; bottom: 2.5em; right: 2em;">
            <div class="mobile-menu-icon" :class="{ show : showMobileNav }" @click.stop="$emit('changing-route', false), $store.commit('setMobileNavState', !showMobileNav)">
                <span></span>
                <span></span>
                <span></span>
            </div>
            <!-- <MenuIcon @changing-route="setChangingRoute" /> -->
            <!-- <v-icon v-if="!showMobileNav" class="white--text rounded-xl"  @click="$store.commit('setMobileNavState', !showMobileNav), $emit('changing-route', false)">mdi-menu</v-icon>
            <v-icon v-else class="white--text rounded-xl" @click="$store.commit('setMobileNavState', !showMobileNav), $emit('changing-route', false)">mdi-close</v-icon> -->
        </v-btn>
        <!-- <v-bottom-navigation class="glass-background" hide-on-scroll :input-value="showMobileNav" fixed>
            <router-link class="white--text text-decoration-none" to="/"><v-btn class="rounded-0 px-3 text-overline" outlined color="white">Home</v-btn></router-link>
            <router-link class="white--text text-decoration-none" to="/gallery"><v-btn class="rounded-0 px-3 text-overline" outlined color="white">Gallery</v-btn></router-link>
            <router-link class="white--text text-decoration-none" to="/prints"><v-btn class="rounded-0 px-3 text-overline" outlined color="white">Prints</v-btn></router-link>
            <router-link class="white--text text-decoration-none" to="/contact"><v-btn class="rounded-0 px-3 text-overline" outlined color="white">Contact</v-btn></router-link>
            <router-link class="white--text text-decoration-none" to="/cart"><v-btn class="rounded-0 px-3 text-overline" outlined color="white">Cart ({{cartItemAmount}})</v-btn></router-link>
        </v-bottom-navigation> -->
        <transition name="slide-up">
            <div class="mobile-nav-menu glass-border" v-touch="{ down: () => handleSwipeOnMobileMenu('down') }" v-show="showMobileNav">
                <div class="d-flex flex-column justify-space-around align-center">
                    <template v-for="item in navItems">
                        <router-link class="white--text mb-4 text-decoration-none" :to="item.url" :key="item.text"><v-btn @click="$store.commit('setMobileNavState', !showMobileNav), $emit('changing-route', true)" class="rounded-0 px-3 text-overline" outlined color="white">{{item.text}} <span v-if="item.text === 'Cart' ">({{cartItemAmount}})</span></v-btn></router-link>
                    </template>
                    <!-- <router-link class="white--text text-decoration-none" to="/"><v-btn class="rounded-0 px-3 text-overline" outlined color="white">Home</v-btn></router-link>
                    <router-link class="white--text text-decoration-none" to="/gallery"><v-btn class="rounded-0 px-3 text-overline" outlined color="white">Gallery</v-btn></router-link>
                    <router-link class="white--text text-decoration-none" to="/prints"><v-btn class="rounded-0 px-3 text-overline" outlined color="white">Prints</v-btn></router-link>
                    <router-link class="white--text text-decoration-none" to="/contact"><v-btn class="rounded-0 px-3 text-overline" outlined color="white">Contact</v-btn></router-link>
                    <router-link class="white--text text-decoration-none" to="/cart"><v-btn class="rounded-0 px-3 text-overline" outlined color="white">Cart ({{cartItemAmount}})</v-btn></router-link> -->
                </div>

                <v-menu v-if="isAdmin" transition="slide-y-transition" bottom offset-y open-on-hover>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn class="rounded-0 white--text text-decoration-none text-overline px-3" outlined color="white" v-on="on" v-bind="attrs">Admin</v-btn>
                    </template>
                    <v-list color="navbar-glass darken-1">
                        <v-list-item class="admin-dropdown-link" @click="$router.push({path: '/admin/new'}), $store.commit('setMobileNavState', !showMobileNav), $emit('changing-route', true)">
                            <v-list-item-title class="white--text" >New Product</v-list-item-title>
                        </v-list-item>
                        <v-list-item class="admin-dropdown-link" @click="$router.push({path: '/admin'}), $store.commit('setMobileNavState', !showMobileNav), $emit('changing-route', true)">
                            <v-list-item-title class="white--text" >Products</v-list-item-title>
                        </v-list-item>
                        <v-list-item class="admin-dropdown-link" @click="signAdminOut(), $store.commit('setMobileNavState', !showMobileNav), $emit('changing-route', true)"> 
                            <v-list-item-title class="white--text" >Sign Out</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>


            </div>
        </transition>
    </div>
</template>
<script>
// import MenuIcon from "./MenuIcon.vue"
export default {
    name: "MobileNav",
    components: {
        // MenuIcon,
    },
    props: ["navItems"],
    data(){
        return{
        }
    },
    methods: {
        handleSwipeOnMobileMenu(direction){
            if(direction === 'down'){
                this.$store.commit('setMobileNavState', !this.showMobileNav)
            }
        }
    },
    computed: {
        isAdmin(){
            return this.$store.state.isAdmin;
        },
        navbarColor(){
            return this.$store.state.navbarColor;
        },
        navbarApp(){
            return this.$store.state.navbarApp;
        },
        navbarGlass(){
            return this.$store.state.navbarGlass;
        },
        cartItemAmount(){
            return this.$store.getters['cartStore/totalItemsInCart'];
        },
        showMobileNav(){
            return this.$store.state.showMobileNav;
        }
    }
}
</script>
<style scoped>

.mobile-nav-menu {
    position: fixed;
    top: 15%;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 14;
    height: 85vh;
    width: 100vw;
    border-radius: 1rem 1rem 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: 0px -3px 15px 5px rgba(26, 26, 26, 0.5);
    background-color: rgba(24, 54, 66, 0.55) !important;
    -webkit-backdrop-filter: blur(12px) !important;
    backdrop-filter: blur(12px) !important;
}

.mobile-menu-icon {
    display:flex;
    flex-direction:column;
    justify-content: flex-end;
    align-items: flex-end;
    width: 20px;
    cursor:pointer;
}

.mobile-menu-icon  span{
    background: #fff;
    border-radius:10px;
    height:2px;
    margin: 2px 0;
    transition: .2s  cubic-bezier(0.68, -0.6, 0.32, 1.6);
}

.mobile-menu-icon span:nth-of-type(1){
    width:50%;
}

.mobile-menu-icon span:nth-of-type(2){
    width:100%;
}

.mobile-menu-icon span:nth-of-type(3){
    width:75%;
}

.mobile-menu-icon.show  span:nth-of-type(1){
    transform-origin:bottom;
    transform: translate(-9px,2px) rotatez(45deg);
}

.mobile-menu-icon.show  span:nth-of-type(2){
    transform-origin:top;
    transform:rotatez(-45deg)
}

.mobile-menu-icon.show  span:nth-of-type(3){
  transform-origin:bottom;
  width:50%;
  transform:rotatez(45deg) translate(-3px,-1px)
}


/* transitions */
.slide-up-enter {
    top: 100%;
}

.slide-up-enter-to {
    top: 0;
}

.slide-up-enter-active {
    transition: all .2s ease-in;
}

.slide-up-leave-active {
    transition: all .2s ease-out;
}

.slide-up-leave-to {
    top: 100%;
}

/* .slide-up-enter, .slide-up-leave-active {
    transform: translateX(10px);
    background-color: red;
    opacity: 0;
} */
    
</style>