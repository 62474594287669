<template>
<div>
    <div class="white--text text-center text-h4">New Product</div>
    <v-row justify="center">
        <v-col xs="12" sm="10" md="6" lg="6" xl="7">

            <v-form @submit.prevent="addProduct()" ref="newProductForm" v-model="formIsValid" lazy-validation class="d-flex flex-column flex-sm-row justify-space-between glass-background glass-border pa-4 rounded-xl elevation-3" style="position: relative;">
                <v-col cols="12" xl="8">

                    <v-col cols="6" class="pb-0 px-0">
                        <v-text-field v-model="product.name" :counter="25" required label="Name" :rules="nameRules" outlined dense></v-text-field>
                    </v-col>


                    <v-col cols="12" class="px-0 mb-4">
                        <div class="py-4 glass-border rounded-xl">
                            <v-col cols="12" class="pb-0 pt-2">
                                <v-row class="justify-start">
                                    <v-col cols="4" class="py-0">
                                        <v-select v-model="selectedMaterial" class="my-2" label="Print" :items="filteredProductVariantMaterials" :item-text="item => item.name" @change="clearSelectedSizes()" return-object validate-on-blur outlined dense></v-select>
                                    </v-col>

                                    <v-col cols="6" class="py-0">
                                        <v-select v-model="selectedSizes" class="my-2" label="Sizes"  :items="getProductVariantSizes" multiple outlined dense></v-select>
                                    </v-col>

                                    <v-col cols="2" class="py-0">
                                        <v-btn class="primary my-2" depressed :disabled="selectedSizes.length === 0" @click="addMaterialToNewProduct()"><v-icon class="white--text">mdi-plus</v-icon></v-btn>
                                    </v-col>

                                </v-row>
                            </v-col>

                            <v-col cols="12" class="py-0">
                                <v-row justify="start">
                                    <v-col cols="6" class="py-0">
                                        <v-select v-model="product.type" class="my-2" label="Type" :rules="[v => !!v || 'Picture type is required']" :items="types" outlined validate-on-blur dense ></v-select>
                                    </v-col>
                                </v-row>
                            </v-col>

                            <v-col cols="12" class="py-0">
                                <v-row class="justify-start">
                                    <v-col cols="7" class="py-0">
                                        <v-text-field v-model="product.location" class="my-2" :rules="locationRules" label="Location" outlined dense validate-on-blur ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-col>

                            <v-row justify="start" align="center" class="full-width px-3">
                                <!-- TODO: ADD RULES FOR THE IMAGES -->
                                <v-col cols="11" class="py-0">
                                    <v-file-input v-model="product.images" class="my-2" :rules="imageRules" multiple required label="Images" accept="image/*" type="file" validate-on-blur outlined show-size :counter="maximumImages" dense prepend-inner-icon="mdi-camera" prepend-icon=""></v-file-input>
                                </v-col>
                            </v-row>
                        </div>
                    </v-col>

                    <v-btn depressed @click="clear" class="info">Clear</v-btn>
                    <v-btn depressed type="submit" :disabled="!formIsValid || this.options.length < 1" class="success ml-2">Add Product</v-btn>
                </v-col>


                <v-divider vertical inset class="glass-border mr-8 py-4"></v-divider>

                
                <v-col cols="12" xl="3">
                    <template v-if="options.length > 0">
                        <v-row v-for="(option, i) in options" :key="i" class="px-2 py-2 mb-4 options-glass-container rounded-lg" style="position: relative;">
                            <v-btn :ripple="false" icon @click="clearOption(i)" class="mt-n2" style="position: absolute; top 0; left: 0;">
                                <v-icon class="red--text text--lighten-2">mdi-delete</v-icon>
                            </v-btn>
                            <div class="full-width d-flex flex-column justify-space-between align-center grey--text text--lighten-4">
                                <p class="mb-0 font-weight-medium">{{option.material.name}}</p> 
                                <!-- <v-divider vertical class="primary"></v-divider> -->
                                <p class="mb-0"><span v-for="(size, j) in option.sizes" :key="j">{{size}} <span v-if="j+1 !== option.sizes.length"> | </span> </span></p>
                            </div>
                        </v-row>
                    </template>
                </v-col>


                
            </v-form>

        </v-col>
    </v-row>

</div>
</template>


<script>
import firebase from "../../../firebaseConfig.js";
export default {
    name: "NewProduct",
    components: {
    },
    data() {
        return {
            formIsValid: false,
            maximumImages: 6,
            MARKUP: 1.60, // 60% markup price
            types: ["Panoramic", "Landscape", "Portrait"],
            product: {
                name: "",
                location: "",
                options: {},
                type: "",
                images: [],
            },
            // productImageUrls: {},
            options: [],
            selectedMaterial: "",
            selectedSizes: [],
            nameRules: [
                v => !!v || 'Name is required',
                v => (v && v.length <= 25) || 'Name must be less than 25 characters',
            ],
            locationRules: [
                v => !!v || 'Location is required',
                v => (v && v.length <= 25) || 'Location must be less than 25 characters',
            ],
            // TODO: get correct image rules!
            imageRules: [
                (value) => !value || (value.length <= 6 && !value.some(val => val.size > 6291456) ) || 'Only 6 Product Images Can Be Used, Each 6MB or less.',
            ],
            materialRules: [
                (value) => !value || this.options.length < 1 || 'Print Material is required.'
            ],
            sizesRules: [v => !!v || 'Minimum one size is required'],
            dbRefs: {
                conversionRef: null,
            },
            conversionHasBeenTriggered: false,
            timesRan: 0,

        }
    },
    methods: {
        clear () {
             this.options = [];
             this.$refs.newProductForm.reset();
        },
        clearOption(index){
            this.options.splice(index, 1);
            // this.$forceUpdate();
        },
        addMaterialToNewProduct(){
            // this.options.push({ material: this.selectedMaterial, sizes: JSON.parse(JSON.stringify(this.selectedSizes))});
            this.options.push({ material: this.selectedMaterial, sizes: this.selectedSizes });
            this.selectedSizes = [];
            this.selectedMaterial = "";
        },
        setRTDBRef(){
            return new Promise((resolve, reject) => {
                this.dbRefs.conversionRef.on(
                "child_added",
                (snapshot) => {
                    // console.log("got a snapshot from the cloud function!" , snapshot.val().key);
                     /* by returning here we keep the work from being done twice. 
                     by returning we don't resolve the promise, so it doesn't move on from the await 
                     where called before until after the second time it has been run (and gets the url's and writes them!) */
                    if(!this.conversionHasBeenTriggered){
                        this.conversionHasBeenTriggered = true;
                        return;
                    }
                    if (snapshot.val() == null) {
                        return;
                    } 
                    else {
                        if (snapshot.val().converted){
                            // console.log("converted: ", snapshot.val().converted);
                            let images = {};
                            var ref = firebase.storage.ref(`productImages/${this.product.id}`);
                            ref.listAll()
                            .then( async (res) => {
                                // console.log("res.items", res.items);
                                    for(const itemRef in res.items){
                                        // console.log("item ref ", res.items[itemRef]);
                                        // console.log("item full name: ", res.items[itemRef].name);
                                        await res.items[itemRef].getDownloadURL().then((url) => {
                                            // console.log("url is: ", url)
                                            // TODO : just take and write these urls to the firestore
                                            // console.log("name", res.items[itemRef].name.split('_')[0])
                                            const sizewith = res.items[itemRef].name.split('_')[1];
                                            const key = res.items[itemRef].name.split('_')[0];
                                            const size = sizewith.split('.')[0] === '' ? 'original' : sizewith.split('.')[0];
                                            // console.log("size key", size);
                                            if (typeof key !== 'undefined' && typeof images[`${key}`] === 'undefined'){
                                                images[`${key}`] = {}; // will create an empty object the first time around
                                            }
                                            // urls.push({ url: url, name: res.items[itemRef].name.split('_')[0] + '.jpg'});
                                            if (typeof key !== 'undefined' && typeof size !== 'undefined'){
                                                images[`${key}`][`${size}`] = url;
                                            }
                                        })
                                    }
                                    // console.log("new images after getting the urls: ", images);
                                    const productMetaDataRef = firebase.db.collection("products").doc(this.product.id);
                                    await productMetaDataRef.update({
                                        'images': images,
                                    }, {merge: true})
                                    return resolve();
                            })
                            .catch((err) => {
                                console.error("Error retrieving the compressed files for the product!", err);
                                reject();
                            })
                            
                        }
                        // console.log("getting finished with a RTDB ref snapshot")
                    }
                },
                (err) => {
                    console.log("Error with conversion ref: ", err);
                    reject();
                });
            })

        },
        async addProduct() {
            this.$store.dispatch('setLoadingState', {on: true});
            /* step 1: validate the form */
            let result = this.$refs.newProductForm.validate();
            // todo: images must have atleast one
            if (result === false || this.product.images.length <= 0){
                this.$store.dispatch('setLoadingState', {on: false});
                return;
            }

            /* step 1.5: prepare the options field */
            await this.prepareOptionsField();

            /* step 2: generate the productId */
            var productId = firebase.db.collection("products").doc();
            this.product.id = productId.id;
            this.dbRefs.conversionRef = firebase.rtDb.ref(`productImages/${this.product.id}`);

            /* step 3: create a storage reference, then loop through the images and upload them to the storage reference */
            let storageRef = firebase.storage.ref();

            // we don't loop through the max image count because we may only upload 1 or 2
            var metadata = {
                cacheControl: 'public,max-age=86400',
                customMetadata: {
                    compressed: false,
                }
            }
            for( var i = 0; i < this.product.images.length; i++ ){
                let productImageRef = storageRef.child(`productImages/${this.product.id}/${this.product.images[i].name}`);
                await productImageRef.put(this.product.images[i], metadata)
                    .then(async (snapshot) => {
                        /* step 4: get the download URL of the image */
                        await snapshot.ref.getDownloadURL()
                            .then((url) => {
                                // this.productImageUrls[this.product.images[i].name] = { url: url, name: this.product.images[i].name };
                                this.product.images[i] = { url: url, name: this.product.images[i].name };
                            })
                    })
                    .catch((error) => {
                        console.error("Error saving images: ", error);
                        this.$store.dispatch('setLoadingState', {on: false});
                        this.$store.dispatch('alertUser', { on: true, text: `Error Saving Images For the Product ${this.product.id}`});
                    })
            }

            /* step 5: prepare the material, the sizes and prices */

            /* step 6: upload the product to the firestore products collection */
            const productMetaDataRef = firebase.db.collection("products").doc(this.product.id);
            await productMetaDataRef.set({
                id: this.product.id,
                name: this.product.name,
                location: this.product.location,
                type: this.product.type,
                // images: this.product.images,
                variants: this.product.options,
                purchased: 0,
                dateCreated: new Date(),
            }).then(() => {
                // TODO: let user know it has been uploaded!
            })
            .catch((error) => {
                this.$store.dispatch('alertUser', { on: true, text: `Error Saving The Product ${this.product.name} To The Collection.`});
                this.$store.dispatch('setLoadingState', {on: false});
                console.error("Error uploading to the product collection:", error);
            })

            await this.setRTDBRef();
            // console.log("after setting the RTDBRef");
            this.$store.dispatch('setLoadingState', {on: false});
            this.$store.dispatch('alertUser', { on: true, text: `Product ${this.product.name} saved!`});
            /* because the admin will be the only one that sees new products, (the user will have the products in the store which is fine)
            we want to request the products */
            this.$store.dispatch('getAllProducts');
            this.clear();
            this.selectedSizes = [];
            this.options = [];
            if (this.dbRefs.conversionRef !== null){
                this.dbRefs.conversionRef.off(); // TODO: FIND OUT HOW TO COMPLETELY SHUT THIS OFF!
                this.dbRefs.conversionRef.remove();
            }
        },
        prepareOptionsField(){
            return new Promise((resolve) => {
                this.options.forEach((option) => {
                    var o = {
                        id: option.material.id,
                        material: option.material.name,
                        sizes: {},
                    };
                    option.sizes.forEach((size) => {
                        // var s = {
                        //     size: option.material.sizes[size],
                        // };
                        o.sizes[size] = option.material.sizes[size];
                    })
                    // this.product.options.push(o);
                    this.product.options[o.id] = o;
                })
                resolve();
            })
        },
        // triggerFileInput(type){
            
        //     this.$refs.newImageInput.$refs.input.click();
        // },
        clearSelectedSizes(){
            this.selectedSizes = [];
        }
    },
    computed: {
        reverseNewProducts() {
            return this.newProducts.slice().reverse();
        },
        getProductVariants(){
            return this.$store.state.productVariants;
        },
        getProductVariantMaterials(){
            return this.$store.getters.productVariantMaterials;
        },
         /* this is used to get all the available product materials that haven't already been added! */
        filteredProductVariantMaterials(){
            let availableMaterials = JSON.parse(JSON.stringify(this.getProductVariantMaterials));
            this.options.forEach((option) => {
                let indexToSlice = availableMaterials.findIndex(material => material.id == option.material.id)
                if (indexToSlice !== -1){
                    availableMaterials.splice(indexToSlice, 1);
                }
            });
            return availableMaterials;
        },
        getProductVariantSizes(){
            if(this.selectedMaterial){
                return this.$store.getters.getProductVariantSizes(this.selectedMaterial.id);
            }
            return [];
        }
    },
    mounted(){
        
    },
    created(){

        //  var ref = firebase.storage.ref(`productImages/${this.getProduct.id}`);
        // ref.listAll()
        // .then((res) => {
        //     res.items.forEach((itemRef) => {
        //         res.items[itemRef].getDownloadURL().then((url) => {
        //             console.log("url is: ", url)
        //         })
        //     })
        // })
        // .catch((err) => {
        //     console.error("Error retrieving the files for the product!");
        // })


        /* BELOW IS A WAY TO CREATE A NEW VARIANT!! */
        // const newVariantRef = firebase.db.collection("variants").doc();
        // const data = {
        //     id: null,
        //     name: "Basic",
        //     sizes: {
        //         "5x7": Math.round(7.99 * 1.65) + 0.99,
        //         "8x12": Math.round(9.99 * 1.65) + 0.99,
        //         "11x14": Math.round(13.99 * 1.65) + 0.99,
        //         "12x18": Math.round(13.99 * 1.65) + 0.99,
        //         "16x20": Math.round(19.99 * 1.65) + 0.99,
                // "16x32": Math.round(52.99 * 1.65) + 0.99,
                // "20x30": Math.round(57.99 * 1.65) + 0.99,
                // "24x36": Math.round(59.99 * 1.65) + 0.99,
                // "16x48": Math.round(79.99 * 1.65) + 0.99,
                // "30x40": Math.round(119.99 * 1.65) + 0.99,
            // }
        // }
        // newVariantRef.set(data);
    },
    beforeDestroy(){
        if (this.dbRefs.conversionRef !== null){
            this.dbRefs.conversionRef.off();
            this.dbRefs.conversionRef.remove();
        }
    }
}
</script>

<style>

.options-glass-container {
    background-color: rgba(24, 54, 66, 0.55) !important;
    -webkit-backdrop-filter: blur(12px) !important;
    backdrop-filter: blur(12px) !important;
}
    
</style>