<template>
<div class="py-16 smoke fill-height">
    <v-container class="py-16">

        <v-row justify="center" class="py-10 mr-0 ml-0 fill-width">
            <v-col xl="3" lg="4" sm="6" xs="8" class="d-flex justify-center">
                <v-tabs centered v-model="selectedTab" :show-arrows="false" @change="changeGallery">
                    <v-tab text class="black--text text-decoration-none text-caption text-uppercase">All</v-tab>
                    <v-tab class="black--text text-decoration-none text-caption text-uppercase">Landscape</v-tab>
                    <v-tab class="black--text text-decoration-none text-caption text-uppercase">Portrait</v-tab>
                    <v-tab class="black--text text-decoration-none text-caption text-uppercase">Panoramic</v-tab>
                </v-tabs>
            </v-col>
        </v-row>

        <v-row class="fill-width mx-0" justify="start">
            <!-- all products -->
            <template v-if="selectedTab === 0" >
                <v-col v-for="product in allProducts" :key="product.id" cols="12" xl="3" lg="4" md="4" sm="5" xs="6">
                    <ProductCard :edit="false" :product="product"></ProductCard>
                </v-col>
            </template>
            <!-- landscape -->
            <template v-else-if="selectedTab === 1" > 
                <v-col v-for="product in landscapeProducts" :key="product.id" cols="12" xl="3" lg="4" md="4" sm="5" xs="6">
                    <ProductCard :edit="false" :product="product"></ProductCard>
                </v-col>
            </template>
            <!-- portrait -->
            <template v-else-if="selectedTab === 2" >
                <v-col v-for="product in portraitProducts" :key="product.id" cols="12" xl="3" lg="4" md="4" sm="5" xs="6">
                    <ProductCard :edit="false" :product="product"></ProductCard>
                </v-col>
            </template>
            <!-- panoramic -->
            <template v-else >
                <v-col v-for="product in panoramicProducts" :key="product.id" cols="12" xl="3" lg="4" md="4" sm="5" xs="6">
                    <ProductCard :edit="false" :product="product"></ProductCard>
                </v-col>
            </template>

        </v-row>

    </v-container>
</div>
</template>
<script>
import ProductCard from "../components/ProductCard.vue";
export default {
    name: "PrintStore",
    components:{
        ProductCard,
    },
    data() {
        return {
            products: [
                { src: require("../assets/gallery/valley_of_fire2.jpg"), title: "Slots", prices: [30, 75, 150], flex: 4 },
                { src: require("../assets/gallery/beach_costa_rica_tropical.jpg"), title: "Bryce Canyon", prices: [30, 75, 150], flex: 2 },
                { src: require("../assets/gallery/beach_costa_rica.jpg"), title: "Kenai Lake", prices: [30, 75, 150], flex: 6 },
                { src: require("../assets/gallery/doom_clouds.jpg"), title: "Alaskan Rivers B&W", prices: [30, 75, 150], flex: 4 },
                { src: require("../assets/gallery/bryce_sunrise.jpg"), title: "Navajo Lake", prices: [30, 75, 150], flex: 6 },
                { src: require("../assets/gallery/halibut_cove.jpg"), title: "Alaskan Rivers B&W", prices: [30, 75, 150], flex: 4 },
                { src: require('../assets/gallery/snowy_mountains_utah.jpg'), title: "Hot Air Balloon", prices: [30, 75, 150], flex: 12 },
            ],
            selectedTab: 0, // 0 -> All | 1 -> Landscape | 2 -> Portrait | 3 -> Panoramic
        }
    },
    methods: {
        changeGallery: function(tab){
            console.log("tab", tab);
        }

    },
    computed: {
        allProducts(){
            return this.$store.getters.allProducts;
        },
        landscapeProducts(){
            return this.$store.getters.landscapeProducts;
        },
        portraitProducts(){
            return this.$store.getters.portraitProducts;
        },
        panoramicProducts(){
            return this.$store.getters.panoramicProducts;
        }
    },
    created: function(){
        // TODO: shuffle the products array.
    }
}
</script>

<style>

.v-tab:before{
    background: none !important;
}

.products {
    line-height: 0;
}

.prints-background {
    background-image: linear-gradient(180deg, var(--v-smoke-base) 0%,  var(--v-primary-base) 30%, var(--v-smoke-base)  100%);
    background-repeat: repeat-y;
}

</style>