<template>
<div>
    <div class="white--text text-center text-h4">Edit Product</div>
    <v-row justify="center">
        <v-col xs="12" sm="10" md="6" lg="6" xl="7">

            <v-form @submit.prevent="saveChanges()" ref="editProductForm" v-model="formIsValid" lazy-validation class="d-flex flex-row justify-space-between glass-background glass-border pa-4 rounded-xl elevation-3" style="position: relative;">
                <v-col cols="12" xl="8">

                    <v-col cols="6" class="pb-0 px-0">
                       <v-text-field v-model="editedProduct.name" :counter="25" label="Name" :placeholder="editedProduct.name" :rules="nameRules" outlined dense></v-text-field>
                    </v-col>

                    <v-col cols="12" class="px-0 mb-4">
                        <div class="py-4 glass-border rounded-xl">
                            <v-col cols="12" class="pb-0 pt-2">
                                <v-row class="justify-start">
                                    
                                    <v-col cols="4" class="py-0">
                                        <v-select v-model="selectedMaterial" class="my-2" label="Print" :items="filteredProductVariantMaterials" :item-text="item => item.name" @change="selectedSizes = [];" return-object validate-on-blur outlined dense></v-select>
                                    </v-col>

                                    <v-col cols="6" class="py-0">
                                        <v-select v-model="selectedSizes" class="my-2" label="Sizes" :items="getProductVariantSizes" multiple outlined dense></v-select>
                                    </v-col>

                                    <v-col cols="2" class="py-0">
                                        <v-btn class="primary my-2" depressed :disabled="selectedSizes.length === 0" @click="addMaterialToNewProduct()"><v-icon class="white--text">mdi-plus</v-icon></v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>

                            <v-col cols="12" class="py-0">
                                <v-row justify="start">
                                    <v-col cols="6" class="py-0">
                                        <v-select v-model="editedProduct.type" label="Type" class="my-2" :rules="[v => !!v || 'Picture type is required']" :items="types" outlined validate-on-blur dense ></v-select>
                                    </v-col>
                                </v-row>
                            </v-col>

                            <v-col cols="12" class="py-0">
                                <v-row class="justify-start">
                                    <v-col cols="7" class="py-0">
                                        <v-text-field v-model="editedProduct.location" class="my-2" :rules="locationRules" label="Location" outlined dense validate-on-blur ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-col>

                        </div>
                    </v-col>

                    <v-col cols="12" class="py-0">
                        <v-row class="justify-start" align="center">

                            <v-col v-for="(image,i) in editedProduct.images" :key="editedProduct.images[image]" cols="3" class="py-0">
                                <v-btn x-small icon class="mt-n2 mb-n7 ml-n4" style="z-index: 1;" :disabled="Object.keys(editedProduct.images).length === 1 && newImages.length == 0" @click="deleteImage(image, i)">
                                    <v-icon class="red--text text--darken-1">mdi-close-circle</v-icon>
                                </v-btn>
                                <v-img :src="image.md" height="80" class="mb-4 align-start justify-start"></v-img>
                            </v-col>

                            <v-col v-for="(image,j) in newLocalImages" :key="j" cols="3" class="py-0">
                                <v-btn x-small icon class="mt-n2 mb-n7 ml-n4" style="z-index: 1;" @click="deleteNewImage(j)">
                                    <v-icon class="red--text text--darken-1">mdi-close-circle</v-icon>
                                </v-btn>
                                <v-img :src="image" height="80" class="mb-4 align-start justify-start"></v-img>
                            </v-col>

                            <v-col v-if="editedProduct.images && Object.keys(editedProduct.images).length + newImages.length < 6" cols="3" style="height: 80px;" class="py-0 mt-2">
                                <div class="addImageInput glass-background-hover glass-border rounded-sm" style="cursor: pointer;" @click="triggerFileInput">
                                    <v-icon>mdi-plus</v-icon>
                                    <v-file-input v-model="newImage" ref="newImageInput" :rules="imageRules" class="pa-0 ml-7 mt-5 text-center" style="display: none;" @change="addNewImage()"  hide-input required label="Images" accept="image/*" type="file" validate-on-blur show-size prepend-icon="mdi-image-plus"></v-file-input>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>

                
                    <div :class="[ !formIsValid || !hasMadeChanges || !hasProductImagesAndVariants  ? 'disabled-button' : 'buy-button']" class="action-button mt-6" @click="saveChanges()">
                        <div class="d-flex justify-center align-center text-subtitle font-weight-medium">
                            SAVE
                        </div>
                    </div>

                </v-col>
                
                 <v-divider vertical inset class="glass-border mr-8 py-4"></v-divider>
                
                <v-col cols="12" xl="3">
                    <template v-if="editedProduct.variants">
                        
                        
                        <v-row v-for="(option, i) in editedProduct.variants" :key="i" transition="scroll-x-transition" class="px-2 py-2 mb-4 options-glass-container rounded-lg" style="position: relative;">
                            <v-btn :ripple="false" icon @click="deleteVariant(i)" class="mt-n2" style="position: absolute; top 0; left: 0;">
                                <v-icon class="red--text text--lighten-2">mdi-delete</v-icon>
                            </v-btn>
                        <transition name="slide-right">

                            <div class="full-width d-flex flex-column justify-space-between align-center grey--text text--lighten-4">
                                <p class="mb-0 font-weight-medium">{{option.material}}</p> 
                                <!-- <v-divider vertical class="primary"></v-divider> -->
                                <p class="mb-0"><span v-for="(size, j) in Object.keys(option.sizes)" :key="j">{{size}} <span v-if="j+1 !== Object.keys(option.sizes).length"> | </span> </span></p>
                            </div>
                        </transition>

                        </v-row>

                    </template>
                </v-col>

            </v-form>

        </v-col>
    </v-row>
</div>
</template>

<script>
import firebase from "../../../firebaseConfig.js";
export default {
    name: "Edit",
    components: {

    },
    data() {
        return {
            formIsValid: true,
            editedProduct: {
                name: null,
                id: null,
                images: {},
                location: null,
                type: null,
                variants: {},
            },
            // newProductName: "",
            // editedProduct: null,
            savingProduct: false,
            maximumImages: 6,
            types: ["Panoramic", "Landscape", "Portrait"],
            // options: [],
            selectedMaterial: "",
            selectedSizes: [],
            materials: ["Metal", "Canvas", "Acrylic", "Basic"],
            newImages: [],
            hasDeletedImages: false,
            hasUploadedImages: false,
            newImage: null,
            newLocalImages: [],
            sizes: {
                
            },
            nameRules: [
                v => !!v || 'Name is required',
                v => (v && v.length <= 25) || 'Name must be less than 25 characters',
            ],
            locationRules: [
                v => !!v || 'Location is required',
                v => (v && v.length <= 25) || 'Location must be less than 25 characters',
            ],
            imageRules: [
                (value) => !value || value.size < 6291456 || 'Image size must be 6MB or less!',
            ],
            materialRules: [
                (value) => !value || this.options.length < 1 || 'Print Material is required.'
            ],
            sizesRules: [v => !!v || 'Minimum one size is required'],
            imagesToDelete: [],
            dbRefs: {
                conversionRef: null,
            },
            conversionTriggeredAmount: 0,
        }
    },
    methods: {
        getProductById(){
            let productRef = firebase.db.collection("products").doc(this.$route.params['id']);
            productRef.get()
                .then((doc) => {
                    if(doc.exists){
                        this.setEditedProduct(doc.data());
                    }
                    else {
                        this.$store.dispatch('alertUser', { on: true, text: `Product: ${this.$route.params['id']} does not exist.`});
                    }
                })
                .catch((error) => {
                    console.error("Error retrieving the product from database", error.message);
                })
        },
        setEditedProduct(product){
            this.editedProduct = JSON.parse(JSON.stringify(product));
        },
        addMaterialToNewProduct(){
            let sizes = {};
            this.selectedSizes.forEach((size) => {
                sizes[size] = this.selectedMaterial.sizes[size];
            })
            this.$set(this.editedProduct.variants, this.selectedMaterial.id, { id: this.selectedMaterial.id, material: this.selectedMaterial.name, sizes: sizes })
            // this.editedProduct.variants[this.selectedMaterial.id] = { id: this.selectedMaterial.id, material: this.selectedMaterial.name, sizes: sizes };
            this.selectedSizes = [];
            this.selectedMaterial = "";
            // this.$forceUpdate();
        },
        deleteVariant(key){
            // console.log("delete variant at key: ", key);
            this.$delete(this.editedProduct.variants, key);
            // this.$forceUpdate();
        },
        deleteImage(image, key){
            /* key = the image name as well */
            /* image has all the urls for each of the sizes of the images */
            // console.log("key", key)
            /* first delete from the local object */
            this.$delete(this.editedProduct.images, key)
            // this.editedProduct.images.splice(index, 1);
            /*  then save the image they want to delete to a local array. when they click save we will go through the array
                and delete each of the images and the metadata for that image in firestore */
            this.imagesToDelete.push({ urls: Object.values(image), index: key});
            /* then delete the actual image from storage */
            // let storageRef = firebase.storage.ref();
            // storageRef
            // .child(`productImages/${this.getProduct.id}/${image.name}`)
            // .delete()
            // .then(() => {
            //     // TODO --- read below
            //     /* after we delete the image we must delete the url in the products collection too, by setting the images array to the editedProduct.images */
            //     // firebase.db.collection('')
            //     this.$store.dispatch('alertUser', { on: true, text: `Deleted Image Successfully`});
            // })
            // this.$forceUpdate();
            // console.log("urls to delete", image);
        },
        deleteNewImage(index){
            this.newImages.splice(index, 1);
        },
        addNewImage(){
            //TODO - upload the image to the storage then re-retrieve the product or use the imageurl to display it.
            // console.log("addNewImage!", this.newImage);
            var reader = new FileReader();
            reader.onloadend = () => {
                this.newLocalImages.push(reader.result);
                this.newImage = null;
            }
            this.newImages.push(this.newImage);
            reader.readAsDataURL(this.newImage);
            // this.newImages.push(this.newImage);
        },
        triggerFileInput(){
            this.$refs.newImageInput.$refs.input.click();
        },
        areEqualObjects(left, right) {
            const lValues = Object.values(left);
            const rValues = Object.values(right);
            const lKeys = Object.keys(left);
            const rKeys = Object.keys(right);
            if ( (lKeys.length !== rKeys.length) || (lValues.length !== rValues.length) ){
                return false;
            }
            for (var i = 0; i < lKeys.length; i++) {
                var propName = left[i];
                if(left[propName] !== right[propName]){
                    return false;
                }
            }
            // console.log("are equal")
            return true;
        },
        setRTDBRef(){
            return new Promise((resolve, reject) => {
                this.dbRefs.conversionRef.on(
                "child_added",
                (snapshot) => {
                    // console.log("on snapshot!!")
                    if (snapshot.val() == null) {
                        return;
                    } 
                    else {
                        if (snapshot.val().converted){
                            this.conversionTriggeredAmount += 1;
                            if(this.conversionTriggeredAmount !== this.newImages.length){
                                return;
                            }
                            // console.log("converted: ", snapshot.val().converted);
                            let images = {};
                            var ref = firebase.storage.ref(`productImages/${this.getProduct.id}`);
                            ref.listAll()
                            .then( async (res) => {
                                // console.log("res.items", res.items);
                                    for(const itemRef in res.items){
                                        // console.log("item ref ", res.items[itemRef]);
                                        // console.log("item full name: ", res.items[itemRef].name);
                                        await res.items[itemRef].getDownloadURL().then((url) => {
                                            const sizewith = res.items[itemRef].name.split('_')[1];
                                            const key = res.items[itemRef].name.split('_')[0];
                                            const size = sizewith.split('.')[0] === '' ? 'original' : sizewith.split('.')[0];
                                            if (typeof key !== 'undefined' && typeof images[`${key}`] === 'undefined'){
                                                images[`${key}`] = {}; // will create an empty object the first time around
                                            }
                                            if (typeof key !== 'undefined' && typeof size !== 'undefined'){
                                                images[`${key}`][`${size}`] = url;
                                            }
                                        })
                                    }
                                    this.editedProduct.images = images;
                                    this.newImages = [];
                                    this.newLocalImages = [];
                                    const productMetaDataRef = firebase.db.collection("products").doc(this.getProduct.id);
                                    await productMetaDataRef.update({
                                        'images': images,
                                    }, {merge: true})
                                    return resolve();
                            })
                            .catch((err) => {
                                console.error("Error retrieving the compressed files for the product!", err);
                                reject();
                            })
                            
                        }
                    }
                },
                (err) => {
                    console.log("Error with conversion ref: ", err);
                    reject();
                });
            })

        },
        /* deleteImagesFromStorage() will delete all the images that the admin set to delete, deleting them by url*/
        async deleteImagesFromStorage(){
            if (this.imagesToDelete.length === 0){
                return;
            }
            // const storageRef = firebase.storage.child(this.getProduct.id);
            for(var i = 0; i < this.imagesToDelete.length; i++){
                // console.log(this.imagesToDelete[i]);
                for(var j = 0; j < this.imagesToDelete[i].urls.length; j++){
                    // let imageRef = storageRef.child(this.imagesToDelete[i].urls[j]);
                    let imageRef = firebase.storage.refFromURL(this.imagesToDelete[i].urls[j]);
                    await imageRef.delete()
                        .then(() => {})
                        .catch((error) => console.log("Error deleting the image Reference", error));
                }
            }

        },
        async uploadNewProductImages(){
            // return new Promise( async (resolve, reject) => {
                if (this.newImages.length === 0){
                    return;
                }
                let storageRef = firebase.storage.ref();
                var metadata = {
                    cacheControl: 'public,max-age=86400',
                    customMetadata: {
                        compressed: false,
                    }
                }
                for( var i = 0; i < this.newImages.length; i++ ){
                    // console.log("image name", this.newImages[i])
                    let productImageRef = storageRef.child(`productImages/${this.getProduct.id}/${this.newImages[i].name}`);
                    await productImageRef.put(this.newImages[i], metadata)
                        .then( () => {
                            /* step 4: get the download URL of the image */
                            // await snapshot.ref.getDownloadURL()
                            //     .then(() => {
                            //         // this.editedProduct.images[i] = { url: url, name: this.newImages[i].name };
                            //     })
                        })
                        .catch((error) => {
                            console.error("Error saving images: ", error);
                            this.$store.dispatch('setLoadingState', {on: false});
                            this.$store.dispatch('alertUser', { on: true, text: `Error Saving Images For the Product ${this.getProduct.id}`});
                            // reject();
                        })
                }
                return;
                // resolve();
            // })

        },
        async saveChanges(){
            this.$store.dispatch('setLoadingState', { on: true });
            this.$refs.editProductForm.validate();
            if (!this.hasMadeChanges) {
                this.$store.dispatch('alertUser', { on: true, text: 'No changes have been made to the product to save.'})
                this.$store.dispatch('setLoadingState', {on: false});
                return;
            }
            /* 0. VALIDATE THE FORM */
            if (!this.formIsValid){
                console.log("form is not valid")
                return;
            }
            /* 1. delete all the images in imagesToDelete From the storage. Keep track of that index! */
            this.deleteImagesFromStorage();
            /* 2. Add all the new images in newImages. get their urls and add them to the edited product images*/
            this.uploadNewProductImages();
            // TODO: then delete them from the editedProduct

            /* 2.5 set the db listener */
            if (this.newImages.length !== 0){
                // console.log("setting the realtime listener")
                this.dbRefs.conversionRef = firebase.rtDb.ref(`productImages/${this.editedProduct.id}`);
                await this.setRTDBRef();
            }
            
            /* 3. update the rest of the product document by setting it to editedProduct. */
            console.log("editedProduct images", this.editedProduct.images);
            await firebase.db.collection("products").doc(this.editedProduct.id)
                .update({
                    id: this.editedProduct.id,
                    name: this.editedProduct.name,
                    location: this.editedProduct.location,
                    type: this.editedProduct.type,
                    variants: this.editedProduct.variants,
                    images: this.editedProduct.images,
                })
                .catch((err) => console.log("Error updating the product document", err));

            this.$store.dispatch('setLoadingState', {on: false});
            this.$store.dispatch('alertUser', { on: true, text: `Product ${this.editedProduct.name} saved!`});
            /* because the admin will be the only one that sees edited products, (the user will have the products in the store which is fine)
            we want to request the products */
            this.$store.dispatch('getAllProducts');
            if (this.dbRefs.conversionRef !== null){
                this.dbRefs.conversionRef.off();
                this.dbRefs.conversionRef.remove();
            }
            // TODO: reroute back to all products admin panel OR just clear the edited product and new images back to the original state.
            this.$router.push({ path: '/admin'});
        },
        
        
    },
    mounted(){
        console.log("product", this.getProduct);
        // console.log("materials ")
        // if(typeof this.getProduct.id === 'undefined'){
        //     this.getProductById();
        // }
        // else {
        //     this.setEditedProduct(this.getProduct);
        // }
    },
    created(){
        if(typeof this.getProduct.id === 'undefined'){
            this.getProductById();
        }
        else {
            this.setEditedProduct(this.getProduct);
        }
    },
    beforeDestroy(){
        if (this.dbRefs.conversionRef !== null){
            this.dbRefs.conversionRef.off();
            this.dbRefs.conversionRef.remove();
        }
    },
    computed: {
        getProduct(){
            return this.$store.getters.productById(this.$route.params['id']);
        },
         getProductVariants(){
            return this.$store.state.productVariants;
        },
        getProductVariantMaterials(){
            return this.$store.getters.productVariantMaterials;
        },
          /* this is used to get all the available product materials that haven't already been added! */
        filteredProductVariantMaterials(){
            if (this.editedProduct.id === null){
                return this.getProductVariantMaterials;
            }
            let availableMaterials = JSON.parse(JSON.stringify(this.getProductVariantMaterials));
            for (var key in this.editedProduct.variants){
                // console.log("editedProduct variants" , this.editedProduct.variants[key].material);
                let indexToSlice = availableMaterials.findIndex(material => material.id == this.editedProduct.variants[key].id)
                if (indexToSlice !== -1){
                    availableMaterials.splice(indexToSlice, 1);
                }
            }
            return availableMaterials;
        },
        getProductVariantSizes(){
            if(this.selectedMaterial){
                return this.$store.getters.getProductVariantSizes(this.selectedMaterial.id);
            }
            return [];
        },
        /* this computed property checks to see if the admin has made any changes to the product. Saves us writes if they haven't and try to hit submit. */
        hasMadeChanges(){
            if (this.editedProduct.id === null){
                return false;
            }

            if (this.getProduct.name !== this.editedProduct.name){ return true; }
            else if (this.getProduct.location !== this.editedProduct.location){ return true; }
            else if (this.getProduct.type !== this.editedProduct.type){ return true; }
            else if (!this.areEqualObjects(this.getProduct.variants, this.editedProduct.variants) ){ return true;}
            else if (!this.areEqualObjects(this.getProduct.images, this.editedProduct.images)){ return true; }
            else if (this.newImages.length > 0){ return true; }
            else if (!this.hasProductImagesAndVariants){ return false;}
            else { return false; }
        },
        hasProductImagesAndVariants(){
            // const original = Object.keys(this.getProduct.images).length;
            const edited = Object.keys(this.editedProduct.images).length;
            // console.log("original ", original)
            // console.log("edited", edited);

            if( this.newImages.length === 0 && edited === 0 ){
                return false;
            }
            else if ( Object.keys(this.editedProduct.variants).length === 0){
                return false;
            }
            return true;
        }
    }
}
</script>

<style>
    .addImageInput{
        display: flex;
        justify-content: center;
        align-content: center;
        width: 100%;
        height: 100%;
    }

    /* TRANSITIONS */
    .slide-right-enter-active {
        transition: all 1.3s ease;
    }

    .slide-right-leave-active {
        transition: all 1.5s ease;
    }

    .slide-right-enter, .slide-right-leave-active {
        transform: translateX(10px);
        background-color: red;
        opacity: 0;
    }

</style>