<template>
<v-row class="fill-height" justify="center" align="center">
    <v-col xl="3" lg="6" md="8" sm="10" xs="12">
        <v-card class="white pa-8">
            <v-text-field v-model="email" outlined dense label="Email" required type="email" validate-on-blur :rules="emailRules" prepend-inner-icon="mdi-email"></v-text-field>
            <v-text-field v-model="password" outlined dense label="Password" @keypress.enter="adminLogin()" type="password" required prepend-inner-icon="mdi-lock" validate-on-blur></v-text-field>
            <v-row justify="center" class="d-flex flex-column align-center" v-if="hasErrors">
                <p class="warning--text darken-1 text-center font-italic">{{errorText}}</p>
            </v-row>
            <v-card-actions>
                <v-btn class="primary white--text" @click="adminLogin()">Login</v-btn>
            </v-card-actions>
        </v-card>
    </v-col>
</v-row>
</template>

<script>
import firebase from "../../../firebaseConfig.js";
export default {
    name: "Login",
    components: {

    },
    data() {
        return {
            email: "",
            password: "",
            emailRules: [
                (v) =>
                /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                "E-mail must be valid",
            ],
            errorText: "",
            hasErrors: false,
        }
    },
    methods: {
        adminLogin(){
            this.hasErrors = false;
            this.errorText = "";
            firebase.auth
                .signInWithEmailAndPassword(this.email, this.password)
                    .then((data) => {
                        if(data.user.uid === process.env.VUE_APP_ADMIN_ID){
                            this.$store.dispatch('alertUser', { on: true, text: "Admin Logged In."});
                        }
                        this.$router.replace({ path: "/admin" });
                    })
                    .catch((err) => {
                        this.hasErrors = true;
                        this.errorText = `${this.setHintText(err.code)}`;
                    });
        },
        setHintText: function(error) {
            if ( error == 'auth/wrong-password' || error == 'auth/invalid-email' || error == 'auth/user-not-found'){
                return "Invalid Credentials. Please Check and Try Again.";
            } 
        }
    },
}
</script>