<template>
<div>
    <div class="white--text text-center text-h4">Products</div>
    <v-row class="full-width mx-0" justify="center" align="center">
        <!-- <v-col xs="12" sm="10" md="6" lg="6" xl="12"> -->
            <v-row justify="start" align="center" class="full-width mx-0 py-8" >
                <v-col v-for="product in allProducts" :key="product.id" cols="12" xl="3" lg="4" md="5" sm="6" xs="12">
                    <ProductCard :edit="true" :product="product"></ProductCard>
                </v-col>
            </v-row>
        <!-- </v-col> -->
    </v-row>
</div>
</template>

<script>
import ProductCard from '../../components/ProductCard.vue'
export default {
    name: "Admin",
    components: {
        ProductCard,
    },
    data() {
        return {

        }
    },
    methods: {

    },
    created: function() {

    },
    computed: {
        allProducts(){
            return this.$store.state.products;
        }
    }
}
</script>