<template>
<!-- <div> -->
    <v-app-bar 
    short
    fixed
    outlined
    elevate-on-scroll
    prominent
    :app="navbarApp"
    :color="navbarColor"
    class="px-2 px-md-4 px-lg-8 glass-background-1"
    :class="[navbarGlass ? 'glass-background' : '']"
    style="max-width: 100%; z-index: 10;"
    >
        <div style="width: 100%; height: 100%;" class="d-flex flex-column justify-end flex-sm-row align-sm-end justify-sm-space-between">
            <!-- <v-col xs="12" sm="4" class="pa-0 justify-end align-self-end"> -->
                <!-- <span class="text-left white--text">LOGO HERE</span> -->
                <div class="d-flex fill-height align-end justify-center">
                    <router-link class="accent--text text-decoration-none text-overline" to="/home">
                        <v-img :src='require("../assets/logo_transparent.png")' width="80px" contain></v-img>
                        <!-- <v-icon class="text-left white--text" size="60">mdi-camera</v-icon> -->
                    </router-link>
                </div>
            <!-- </v-col> -->

            <v-col xs="12" sm="6" md="5" lg="4" xl="4">
                <v-row justify="space-between" align="center" class="pb-2">
                    <template v-for="item in navItems">
                        <router-link class="white--text text-decoration-none" :to="item.url" :key="item.text"><v-btn class="rounded-0 px-3 text-overline" outlined color="white">{{item.text}} <span v-if="item.text === 'Cart' ">({{cartItemAmount}})</span></v-btn></router-link>
                    </template>
                    <!-- <router-link class="white--text text-decoration-none" to="/"><v-btn class="rounded-0 px-3 text-overline" outlined color="white">Home</v-btn></router-link>
                    <router-link class="white--text text-decoration-none" to="/gallery"><v-btn class="rounded-0 px-3 text-overline" outlined color="white">Gallery</v-btn></router-link>
                    <router-link class="white--text text-decoration-none" to="/prints"><v-btn class="rounded-0 px-3 text-overline" outlined color="white">Prints</v-btn></router-link>
                    <router-link class="white--text text-decoration-none" to="/contact"><v-btn class="rounded-0 px-3 text-overline" outlined color="white">Contact</v-btn></router-link>
                    <router-link class="white--text text-decoration-none" to="/cart"><v-btn class="rounded-0 px-3 text-overline" outlined color="white">Cart ({{cartItemAmount}})</v-btn></router-link> -->

                    <v-menu v-if="isAdmin" transition="slide-y-transition" bottom offset-y open-on-hover>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn class="rounded-0 white--text text-decoration-none text-overline px-3" outlined color="white" v-on="on" v-bind="attrs">Admin</v-btn>
                        </template>
                        <v-list color="navbar-glass darken-1">
                            <v-list-item class="admin-dropdown-link" @click="$router.push({path: '/admin/new-product'})">
                                <v-list-item-title class="white--text" >New Product</v-list-item-title>
                            </v-list-item>
                            <v-list-item class="admin-dropdown-link" @click="$router.push({path: '/admin/products'})">
                                <v-list-item-title class="white--text" >Products</v-list-item-title>
                            </v-list-item>
                            <v-list-item class="admin-dropdown-link" @click="signAdminOut()"> 
                                <v-list-item-title class="white--text" >Sign Out</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-row>
            </v-col>

            
        </div>
    </v-app-bar>
<!-- </div> -->
</template>


<script>
import firebase from "../../firebaseConfig.js";
export default {
    name: "Navbar",
    components:{

    },
    props: ["navItems"],
    data() {
        return {

        }
    },
    methods: {
        signAdminOut(){
            firebase.auth.signOut();
            this.$router.push({ path: '/home'});
        }
    },
    mounted(){
        // console.log("cart item amount, ", this.cartItemAmount);
    },
    computed: {
        isAdmin(){
            return this.$store.state.isAdmin;
        },
        navbarColor(){
            return this.$store.state.navbarColor;
        },
        navbarApp(){
            return this.$store.state.navbarApp;
        },
        navbarGlass(){
            return this.$store.state.navbarGlass;
        },
        cartItemAmount(){
            return this.$store.getters['cartStore/totalItemsInCart'];
        }
    }
}
</script>

<style scoped>

.v-toolbar__content {
    display: flex !important;
    align-items: flex-end !important;
}

.admin-dropdown-link:hover {
    background-color: var(--v-primary-lighten4);
    cursor: pointer;
    transition: background-color .3s ease;
    color: var(--v-info-base) !important;
}

</style>