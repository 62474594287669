import axios from "axios";

const CartStore = {
    namespaced: true,
    state: () => ({
        cart: [],
    }),
    mutations: {
        saveToCart(state, payload) {
            /* if the product is already in the cart, just increment the quantity */
            console.log("------- PAYLOAD ---------")
            // console.table(payload)
            // console.log((p.id === payload.id))
            // console.log(p.variants.medium === payload.variants.medium)
            // console.log(p.variants.size === payload.variants.size)
            const product = {
                id: payload.id,
                total_quantity: payload.total_quantity,
                variants: payload.variants,
            }
            let index = state.cart.findIndex((p)=> {
                return ( (p.id === payload.id) && (p.variants.medium === payload.variants.medium) && (p.variants.size === payload.variants.size) )
            });
            if (index === -1){
                console.log("first time this product has been added.")
                state.cart.push(product);
            }
            else {
                state.cart[index].variants.quantity += payload.variants.quantity;
                state.cart[index]['total_quantity'] += payload.total_quantity;

                // console.log("this product has been added.")
                // console.log( payload.variants.medium === state.cart[index].variants.medium )
                // console.log(payload.variants.size === state.cart[index].variants.size)
                // if( (payload.variants.medium === state.cart[index].variants.medium) && (payload.variants.size === state.cart[index].variants.size) ){
                //     console.log("this product and this variant are in the cart alread, incrementing by :", payload.variants.quantity);
                //     state.cart[index].variants.quantity += payload.variants.quantity;
                //     state.cart[index]['total_quantity'] += payload.total_quantity;
                // }
                // else {
                //     state.cart.push(product);
                // }
            }
        },
        removeFromCart(state, payload){
            console.log("payload to delete", payload)
            
            const index = state.cart.findIndex( (p) => {
                return ( (p.id === payload.id) && (p.variants.medium === payload.medium) && (p.variants.size === payload.size) )
            });
            

            if (index === -1){
                console.log("Product not in cart")
                return;
            }
            // console.log(state.cart[index].variants.medium === payload.medium);
            // console.log(state.cart[index].variants.size === payload.size);
            // console.log(state.cart[index].total_quantity > 1);
            if(state.cart[index].total_quantity > 1){
                state.cart[index].total_quantity -= 1;
            }
            else {
                state.cart.splice(index, 1);
            }
            
        },
        clearCart(state){
            console.log("Clearing Cart!");
            state.cart = [];
        }
    },
    actions: {
        addToCart({dispatch, commit}, payload){
            // console.log("in adding to cart the payload is", payload);
            commit('saveToCart', payload);
            dispatch('alertUser', {on: true, text: "Successfully added item to cart."}, {root: true,});
        },
        removeItemFromCart({commit}, payload){
            commit('removeFromCart', payload);
        },
        cartCheckout(context){
            return new Promise((resolve) => {
                if (context.state.cart.length == 0){
                    console.log("can't checkout when the cart doesn't have items!")
                }
                else if (context.state.cart.length == 1){
                    console.log("the cart has only one item, send a request to checkout for one item!")
                }
                else if (context.state.cart.length > 1){
                    console.log("multiple items in the cart, send a request to checkout for multiple items!");
                    context.dispatch('createCustomerCheckout')
                    .then((sessionId) => {
                        console.log("in the store the response we will send is:  ", sessionId);

                        resolve(sessionId)
                    })
                }
                else {
                    // shouldn't ever get here...
                    context.dispatch('createCustomerCheckout')
                    .then((sessionId) => {
                        console.log("in the store the response we will send is:  ", sessionId);
                        resolve(sessionId)
                    })
                }
            })

        },
        createCustomerCheckout(context){
            return new Promise((resolve) => {
                console.log("window.location.pathname,", window.location.pathname)
                console.log("STATE.CART", context.state.cart);
                console.log("TOTAL COST OF CART ON CLIENT SIDE: ", context.getters.totalCostOfCart)
                if(context.state.cart.length === 0){
                    console.log("returning because there are no items in the cart!")
                    return;
                }
                axios.post('create-checkout-session-for-multiple-products' ,
                {
                    sent_from: window.location.pathname,
                    products: context.state.cart,
                    total_cost: context.getters.totalCostOfCart,
                    product_ids: context.getters.productIdsInCart,
                    medium_ids: context.getters.mediumIdsInCart,
                }).then((response) => {
                    if (response.data.error){
                        console.log("error creating checkout session : ", response.data.error)
                        resolve(null);
                    }
                    resolve(response.data.id);
                    // context.dispatch('setLoadingState', {on: false});
                    // return 0;
                    // return this._vm.$stripe.methods.redirectToCheckout({ sessionId: response.data.id })
                    // return Stripe(process.env.VUE_APP_STRIPE_TEST_KEY).redirectToCheckout({ sessionId: response.data.id })
                })
                // .then((result)=> {
                //     // If `redirectToCheckout` fails due to a browser or network
                //     // error, you should display the localized error message to your
                //     // customer using `error.message`.
                //     if(result.error){
                //         console.log(result.error.message);
                //     }
                // })
                .catch((err) => {
                    // context.dispatch('setLoadingState', {on: false});
                    // TODO - send in user alert
                    console.log("error creating checkout session: ", err)
                    resolve(null);
                });
            })


        }
    },
    getters: {
        // get product info from the items in the cart
        productsInCart: (state /*, getters, rootState, rootGetters */) => {
            let products = [];
            state.cart.forEach((item) => {
                if (item !== null){
                    // console.log("ITEMMMMMM in cart: ", item);
                    products.push({
                        // product: rootGetters.productById(item.id),
                        id: item.id,
                        total_quantity: item.total_quantity,
                        variants: item.variants,
                    });
                }
            });
            // order by name so when decrementing items in a cart it won't push the product towards the bottom!
            
            return products;
        },
        // get all the ids of the products in the cart, no duplicates
        productIdsInCart: (state) => {
            let ids = [];
            state.cart.forEach((product) => {
                if ( ids.findIndex( (id) => id == product.id ) == -1 ){
                    ids.push(product.id);
                }
            })
            return ids;
        },
        // gets all the medium ids of the products in the cart, no duplicates
        mediumIdsInCart: (state) => {
            let ids = [];
            state.cart.forEach((product) => {
                if ( ids.findIndex( (id) => id === product.variants.medium ) == -1 ){
                    ids.push(product.variants.medium);
                }
            })
            return ids;
        },
        // get total in the cart
        totalItemsInCart: (state) => {
            let total = 0;
            state.cart.forEach((item) => {
                total += item.total_quantity;
            })
            return total;
        },

        //returns the sum of the prices in the cart
        totalCostOfCart: (state) => {
            let total = 0;
            state.cart.forEach((item) => {
                total += item.variants.price * item.total_quantity
            })
            return total;
        }

    }
}





export default CartStore