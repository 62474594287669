import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/database';
import 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyAlssDf_7XDiZoAMVyjLfQojGy0pTugdeo",
    authDomain: "dillanjohnsonphoto.firebaseapp.com",
    projectId: "dillanjohnsonphoto",
    databaseURL: "https://dillanjohnsonphoto-default-rtdb.firebaseio.com",
    storageBucket: "dillanjohnsonphoto.appspot.com",
    messagingSenderId: "1051004292636",
    appId: "1:1051004292636:web:9e88793f1244a9899159d9",
    measurementId: "G-S8RGE2CM1K"
};


// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const auth = firebase.auth();
const storage = firebase.storage();
const rtDb = firebase.database();


export default {
    db,
    auth,
    storage,
    rtDb,
};
